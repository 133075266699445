import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Usersidebar from "../../components/Usersidebar";
import { MyContext } from "../../App";
import { getOrder } from "../../api/PaymentApi";
import {
  Typography,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { rupee } from "../../constants/priceFormats";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const context = useContext(MyContext);
  const fetchOrders = async () => {
    if (context.user) {
      const res = await getOrder(context.user);
      setOrders(res.data);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const daySuffix = (day) => {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return `${day}${daySuffix(day)} ${month}, ${year}`;
  };

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return "Order Placed";
      case 2:
        return "Order Packed";
      case 3:
        return "Out for Delivery";
      case 4:
        return "Delivered";
      default:
        return "Unknown Status";
    }
  };

  const getStatusIconWithText = (status) => {
    const iconProps = { sx: { fontSize: 100 }, color: "inherit" };
    let icon, color, text;

    switch (status) {
      case 1:
        icon = <ShoppingCartIcon {...iconProps} color="primary" />;
        text = "Order Placed";
        color = "primary";
        break;
      case 2:
        icon = <HourglassEmptyIcon {...iconProps} color="secondary" />;
        text = "Order Packed";
        color = "secondary";
        break;
      case 3:
        icon = <LocalShippingIcon {...iconProps} color="action" />;
        text = "Out for Delivery";
        color = "action";
        break;
      case 4:
        icon = <CheckCircleIcon {...iconProps} color="success" />;
        text = "Delivered";
        color = "success";
        break;
      default:
        return null;
    }

    return (
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        {icon}
        <Typography sx={{ marginTop: 1 }} color={color}>
          {text}
        </Typography>
      </Box>
    );
  };

  useEffect(() => {
    fetchOrders();
  }, [context.user]);
  return (
    <>
      {context.windowWidth > 992 && (
        <div className="breadcrumbWrapper mb-4">
          <div className="container-fluid">
            <ul className="breadcrumb breadcrumb2 mb-0">
              <li>
                <Link to={"/"}>Home</Link>
              </li>
              <li>User</li>
              <li>Orders</li>
            </ul>
          </div>
        </div>
      )}

      <section className="listingPage">
        <div className="container-fluid">
          <div className="listingData">
            <div className="row">
              {context.windowWidth > 992 && (
                <div className="col-md-3 sidebarWrapper">
                  <Usersidebar />
                </div>
              )}

              <div
                className={
                  context.windowWidth > 992 ? "col-md-9 pt-0" : "col-md-12 pt-0"
                }
              >
                <div className="card border-0 shadow p-5">
                  <h3 className="mb-3">My Orders </h3>
                  {orders.map((order) => (
                    <Accordion key={order._id}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            paddingRight: "2rem",
                          }}
                        >
                          <Typography sx={{ fontSize: "20px" }}>
                            ORID:{" "}
                            <span style={{ fontWeight: "600" }}>
                              {" "}
                              {order.ORID}{" "}
                            </span>
                          </Typography>
                          <Typography sx={{ fontSize: "20px" }}>
                            <span style={{ fontWeight: "600" }}>
                              {rupee.format(order.total.toFixed(2))}{" "}
                            </span>
                          </Typography>
                          <Typography sx={{ fontSize: "20px" }}>
                            <span style={{ fontWeight: "600" }}>
                              {formatDate(order.purchasedDate)}{" "}
                            </span>
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box sx={{ fontSize: "20px", marginBottom: 2 }}>
                          <TableContainer
                            component={Paper}
                            sx={{ boxShadow: "none" }}
                          >
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Order ID
                                  </TableCell>
                                  <TableCell sx={{ fontSize: "20px" }}>
                                    {order.orderID}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Payment ID
                                  </TableCell>
                                  <TableCell sx={{ fontSize: "20px" }}>
                                    {order.paymentID}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Address
                                  </TableCell>
                                  <TableCell sx={{ fontSize: "20px" }}>
                                    {order.address.merci_name}
                                    <br />
                                    {order.address.merci_mobile}
                                    <br />
                                    {order.address.merci_address},{" "}
                                    {order.address.merci_locality},
                                    {order.address.merci_city},
                                    {order.address.merci_state},{" "}
                                    {order.address.merci_pincode}
                                    <br />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>

                        <Box sx={{ marginTop: 2 }}>
                          <TableContainer
                            component={Paper}
                            sx={{ boxShadow: "none" }}
                          >
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell sx={{ fontSize: "20px" }}>
                                    Image
                                  </TableCell>
                                  <TableCell sx={{ fontSize: "20px" }}>
                                    Name
                                  </TableCell>
                                  <TableCell sx={{ fontSize: "20px" }}>
                                    Count
                                  </TableCell>
                                  <TableCell sx={{ fontSize: "20px" }}>
                                    Status
                                  </TableCell>
                                  <TableCell sx={{ fontSize: "20px" }}>
                                    MRP
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {order.cartData.map((item) => (
                                  <TableRow key={item.id}>
                                    <TableCell>
                                      <img
                                        src={item.merci_prod_img}
                                        alt={item.merci_prod_name}
                                        style={{ width: 200, height: 200 }}
                                      />
                                    </TableCell>
                                    <TableCell sx={{ fontSize: "20px" }}>
                                      {item.merci_prod_name}
                                    </TableCell>
                                    <TableCell sx={{ fontSize: "20px" }}>
                                      {item.merci_count}
                                    </TableCell>
                                    <TableCell sx={{ fontSize: "20px" }}>
                                      {getStatusText(item.status)}
                                    </TableCell>
                                    <TableCell sx={{ fontSize: "20px" }}>
                                      {rupee.format(item.merci_mrp)}
                                    </TableCell>
                                  </TableRow>
                                ))}
                                <TableRow>
                                  <TableCell
                                    sx={{ fontSize: "20px" }}
                                    colSpan={4}
                                    align="right"
                                  >
                                    Total
                                  </TableCell>
                                  <TableCell sx={{ fontSize: "20px" }}>
                                    {rupee.format(order.total)}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                        <Box sx={{ marginTop: 2 }}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            {getStatusIconWithText(1)}
                            <Divider
                              sx={{
                                flex: 1,
                                bgcolor: order.cartData.some(
                                  (item) => item.status === 2
                                )
                                  ? "blue"
                                  : "gray",
                                height: 2,
                                margin: "0 8px",
                              }}
                            />
                            {getStatusIconWithText(2)}
                            <Divider
                              sx={{
                                flex: 1,
                                bgcolor: order.cartData.some(
                                  (item) => item.status === 2
                                )
                                  ? "blue"
                                  : "gray",
                                height: 2,
                                margin: "0 8px",
                              }}
                            />
                            {getStatusIconWithText(3)}
                            <Divider
                              sx={{
                                flex: 1,
                                bgcolor: order.cartData.some(
                                  (item) => item.status === 2
                                )
                                  ? "blue"
                                  : "gray",
                                height: 2,
                                margin: "0 8px",
                              }}
                            />
                            {getStatusIconWithText(4)}
                          </Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Orders;
