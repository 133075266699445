import { configureStore } from '@reduxjs/toolkit';
import categoryReducer from '../features/categorySlice';
import userReducer from '../features/UserSlice';
import cjReducer from '../features/productsSlice';
import cartReducer from '../features/cartSlice';
import wishlistReducer from '../features/wishlistSlice';

export const store = configureStore({
  reducer: {
    categories: categoryReducer,
    user: userReducer,
    cjproducts: cjReducer,
    cart: cartReducer,
    wishlist: wishlistReducer
  },
});
