import React, { useEffect, useContext } from "react";
import "./nav.css";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { useState } from "react";
import { MyContext } from "../../../App";
import { IndianMenu } from "../../../constants/IndianMenu";
import Cookies from "js-cookie";

const Nav = (props) => {
  const [navData, setNavData] = useState([]);
  const [isOpenNav, setIsOpenNav] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [openDropdownMenu, setDropdownMenu] = useState(false);
  const [openDropdownMenuIndex, setDropdownMenuIndex] = useState(null);
  const [isLogin, setIsLogin] = useState(false);

  const [openMegaMenu, setOpenMegaMenu] = useState(false);

  const context = useContext(MyContext);

  const signIn = () => {
    if (
      Cookies.get("merciemartuser") !== null &&
      Cookies.get("merciemartuser") !== undefined
    ) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  };

  useEffect(() => {
    setNavData(props.data);
    signIn();
  }, []);

  useEffect(() => {
    setIsOpenNav(props.openNav);
  }, [props.openNav]);

  const closeNav = () => {
    props.closeNav();
  };

  const openDropdownFun = (index) => {
    setDropdownMenu(!openDropdownMenu);
    setDropdownMenuIndex(index);
  };

  const subMenu = (mainMenu) => {
    return (
      <div
        className={`dropdown_menu megaMenu w-100 ${
          openMegaMenu === true && "open"
        }`}
      >
        <div className="row">
          {[
            ...new Set(
              IndianMenu.filter((el) => el.main_cat === mainMenu).map(
                (ele) => ele.sub_cat
              )
            ),
          ].map((item, index) => {
            return (
              <div className="col" key={index}>
                <a
                  href={`/cat/${mainMenu.replaceAll(
                    " ",
                    "_"
                  )}/${item.replaceAll(" ", "_")}`}
                >
                  <h4 className="text-g text-capitalize">{item}</h4>
                </a>
                {
                  <ul className="mt-4 mb-0">
                    {IndianMenu.filter(
                      (el) => el.sub_cat === item && el.main_cat === mainMenu
                    ).map((subitem, ind) => {
                      return (
                        <li key={ind}>
                          <Link
                            onClick={props.closeNav}
                            to={`/cat/${mainMenu.replaceAll(
                              " ",
                              "_"
                            )}/${item.replaceAll(
                              " ",
                              "_"
                            )}/${subitem.low_cat.replaceAll(" ", "_")}`}
                          >
                            {subitem.low_cat}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                }
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      {isOpenNav === true && (
        <div className="navbarOverlay" onClick={props.closeNav}></div>
      )}
      <div
        className={`nav d-flex align-items-center ${
          isOpenNav === true && "click"
        }`}
      >
        <div className="container-fluid">
          <div className="row position-relative">
            {/* <div className='col-sm-2 part1 d-flex align-items-center'>
                            <Button className='bg-g text-white catTab res-hide'>
                                <GridViewIcon /> &nbsp;Browse All Categories <KeyboardArrowDownIcon /></Button>
                        </div> */}

            <div className="col-sm-12 part2 position-static">
              <nav className={isOpenNav === true ? "open" : ""}>
                <ul className="list list-inline mb-0">
                  {/* <li className='list-inline-item'>
                                        <Button><Link to={'/'} onClick={props.closeNav}>Home</Link></Button>
                                    </li> */}
                  <li className="list-inline-item position-static">
                    <Button onClick={() => setOpenMegaMenu(!openMegaMenu)}>
                      <Link to={"/catMain/Computer & Office"}>
                        Computer & Office
                      </Link>
                    </Button>
                    {subMenu("Computer & Office")}
                  </li>
                  <li className="list-inline-item position-static">
                    <Button>
                      <Link
                        to={"/catMain/Consumer Electronics"}
                        onClick={props.closeNav}
                      >
                        Electronics
                      </Link>
                    </Button>
                    {subMenu("Consumer Electronics")}
                  </li>
                  <li className="list-inline-item position-static">
                    <Button>
                      <Link
                        to={"/catMain/Women's Clothing"}
                        onClick={props.closeNav}
                      >
                        Women's Clothing
                      </Link>
                    </Button>
                    {subMenu("Women's Clothing")}
                  </li>
                  <li className="list-inline-item position-static">
                    <Button>
                      <Link
                        to={"/catMain/Men's Clothing"}
                        onClick={props.closeNav}
                      >
                        Men's Clothing
                      </Link>
                    </Button>
                    {subMenu("Men's Clothing")}
                  </li>
                  <li className="list-inline-item position-static">
                    <Button>
                      <Link
                        to={"/catMain/Toys, Kids & Babies"}
                        onClick={props.closeNav}
                      >
                        Kids & Toys
                      </Link>
                    </Button>
                    {subMenu("Toys, Kids & Babies")}
                  </li>
                  <li className="list-inline-item position-static">
                    <Button>
                      <Link
                        to={"/catMain/Home, Garden & Furniture"}
                        onClick={props.closeNav}
                      >
                        Home & Garden
                      </Link>
                    </Button>
                    {subMenu("Home, Garden & Furniture")}
                  </li>
                  <li className="list-inline-item position-static">
                    <Button>
                      <Link
                        to={"/catMain/Bags & Shoes"}
                        onClick={props.closeNav}
                      >
                        Shoes & Bags
                      </Link>
                    </Button>
                    {subMenu("Bags & Shoes")}
                  </li>
                  <li className="list-inline-item position-static">
                    <Button>
                      <Link
                        to={"/catMain/Jewelry & Watches"}
                        onClick={props.closeNav}
                      >
                        Jewelry & Watches
                      </Link>
                    </Button>
                    {subMenu("Jewelry & Watches")}
                  </li>
                  <li className="list-inline-item position-static">
                    <Button>
                      <Link
                        to={"/catMain/Health, Beauty & Hair"}
                        onClick={props.closeNav}
                      >
                        Health & Beauty
                      </Link>
                    </Button>
                    {subMenu("Health, Beauty & Hair")}
                  </li>

                  {/* <li className='list-inline-item position-static'>
                                        <Button onClick={()=>setOpenMegaMenu(!openMegaMenu)}><Link>Shop</Link></Button>
                                        <div className={`dropdown_menu megaMenu w-100 ${openMegaMenu===true && 'open'}`}>
                                            <div className='row'>
                                                {
                                                    props.data.length !== 0 &&
                                                    props.data.map((item, index) => {
                                                        return (
                                                            <div className='col'>
                                                                <a href={`/cat/${item.cat_name.toLowerCase()}`}> <h4 className='text-g text-capitalize'>{item.cat_name}</h4></a>
                                                                {
                                                                    item.items.length !== 0 &&
                                                                    <ul className='mt-4 mb-0'>
                                                                        {
                                                                            item.items.map((item_, index) => {
                                                                                return (
                                                                                    <li>
                                                                                        <Link onClick={props.closeNav} to={`/cat/${item.cat_name.toLowerCase()}/${item_.cat_name.replace(/\s/g, '-').toLowerCase()}`}>{item_.cat_name}</Link>
                                                                                    </li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                }
                                                                
                                                            </div>
                                                        )
                                                    })
                                                }

                                                {/* <div className='col'>
                                                    <img src="https://wp.alithemes.com/html/nest/demo/assets/imgs/banner/banner-menu.png" className='w-100' />
                                                </div> *
                                            </div>
                                        </div>
                                    </li> */}

                  {/* {
                                        navData.length !== 0 &&
                                        navData.map((item, index) => {
                                            return (
                                                <li className='list-inline-item' key={index}>
                                                    <Button onClick={()=>openDropdownFun(index)}><a href={`${windowWidth>992 ? `/cat/${item.cat_name.toLowerCase()}` : '#'}`}
                                                        onClick={() => sessionStorage.setItem('cat', item.cat_name.toLowerCase())}
                                                    >{item.cat_name}  <KeyboardArrowDownIcon  className={`${openDropdownMenu===true && openDropdownMenuIndex===index && 'rotateIcon'}`}/></a></Button>
                                                    {
                                                        item.items.length !== 0 &&
                                                        <div className={`dropdown_menu ${openDropdownMenu===true && openDropdownMenuIndex===index && 'open'}`}>

                                                            <ul>
                                                                {
                                                                    item.items.map((item_, index_) => {
                                                                        return (
                                                                            <li key={index_}>
                                                                                <Button onClick={props.closeNav}>
                                                                                    <a href={`/cat/${item.cat_name.toLowerCase()}/${item_.cat_name.replace(/\s/g, '-').toLowerCase()}`}
                                                                                        onClick={() => sessionStorage.setItem('cat', item.cat_name.toLowerCase())}>
                                                                                        {
                                                                                            item_.cat_name
                                                                                        }
                                                                                    </a>
                                                                                </Button>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }


                                                            </ul>
                                                        </div>
                                                    }
                                                </li>
                                            )

                                        })
                                    }

                                    <li className='list-inline-item'>
                                        <Button onClick={props.closeNav}><Link>About</Link></Button>
                                    </li>


                                    <li className='list-inline-item position-static'>
                                        <Button onClick={()=>setOpenMegaMenu(!openMegaMenu)}><Link>Shop <KeyboardArrowDownIcon   className={`${openMegaMenu===true &&  'rotateIcon'}`}/></Link></Button>
                                        <div className={`dropdown_menu megaMenu w-100 ${openMegaMenu===true && 'open'}`}>
                                            <div className='row'>
                                                {
                                                    props.data.length !== 0 &&
                                                    props.data.map((item, index) => {
                                                        return (
                                                            <div className='col'>
                                                                <a href={`/cat/${item.cat_name.toLowerCase()}`}> <h4 className='text-g text-capitalize'>{item.cat_name}</h4></a>
                                                                {
                                                                    item.items.length !== 0 &&
                                                                    <ul className='mt-4 mb-0'>
                                                                        {
                                                                            item.items.map((item_, index) => {
                                                                                return (
                                                                                    <li>
                                                                                        <Link onClick={props.closeNav} to={`/cat/${item.cat_name.toLowerCase()}/${item_.cat_name.replace(/\s/g, '-').toLowerCase()}`}>{item_.cat_name}</Link>
                                                                                    </li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }

                                                <div className='col'>
                                                    <img src="https://wp.alithemes.com/html/nest/demo/assets/imgs/banner/banner-menu.png" className='w-100' />
                                                </div>
                                            </div>
                                        </div>
                                    </li> */}

                  {/* <li className='list-inline-item'>
                                        <Button><Link>Pages  <KeyboardArrowDownIcon /></Link>
                                        </Button>

                                        <div className='dropdown_menu'>
                                            <ul>
                                                <li><Button><Link to="/about">About Us</Link></Button></li>
                                                <li><Button><Link to="/about">Contact</Link></Button></li>
                                                <li><Button><Link to="/about">My Account</Link></Button></li>
                                                <li><Button><Link to="/about">Login</Link></Button></li>
                                                <li><Button><Link to="/about">Register</Link></Button></li>
                                                <li><Button><Link to="/about">Forgot password</Link></Button></li>
                                                <li><Button><Link to="/about">Reset password</Link></Button></li>
                                                <li><Button><Link to="/about">Purchase Guide</Link></Button></li>
                                                <li><Button><Link to="/about">Privacy Policy</Link></Button></li>
                                                <li><Button><Link to="/about">Terms of Service</Link></Button></li>
                                                <li><Button><Link to="/about">404 Page</Link></Button></li>
                                            </ul>
                                        </div>

                                    </li> */}
                  {/* <li className='list-inline-item'>
                                        <Button><Link>Contact</Link></Button>
                                    </li> */}
                </ul>

                {windowWidth < 992 && (
                  <>
                    { !isLogin && (
                      <div className="pl-3 pr-3">
                        <br />
                        <Link to={"/signIn"}>
                          <Button
                            className="btn btn-g btn-lg w-100"
                            onClick={closeNav}
                          >
                            Sign In
                          </Button>
                        </Link>
                      </div>
                    )}
                  </>
                )}
              </nav>
            </div>

            {/* <div className='col-sm-2 part3 d-flex align-items-center'>
                            <div className='phNo d-flex align-items-center ml-auto'>
                                <span><HeadphonesOutlinedIcon /></span>
                                <div className='info ml-3'>
                                    <h3 className='text-g mb-0'>(+91) 8143272975</h3>
                                    <p className='mb-0'>24/7 Support Center</p>
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Nav;
