export const IndianMenu = [
  {
    "company": "cj",
    "main_cat": "Computer & Office",
    "sub_cat": "Office Electronics",
    "low_cat": "Office & School Supplies",
    "merci_image": "img/categories/home-5/10.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Computer & Office",
    "sub_cat": "Office Electronics",
    "low_cat": "Printer Supplies",
    "merci_image": "img/categories/home-5/10.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Computer & Office",
    "sub_cat": "Office Electronics",
    "low_cat": "3D Printers",
    "merci_image": "img/categories/home-5/10.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Computer & Office",
    "sub_cat": "Office Electronics",
    "low_cat": "3D Pens",
    "merci_image": "img/categories/home-5/10.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Computer & Office",
    "sub_cat": "Office Electronics",
    "low_cat": "Printers",
    "merci_image": "img/categories/home-5/10.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Computer & Office",
    "sub_cat": "Office Electronics",
    "low_cat": "Scanners",
    "merci_image": "img/categories/home-5/10.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Computer & Office",
    "sub_cat": "Security & Protection",
    "low_cat": "Alarm & Sensor",
    "merci_image": "img/categories/home-5/10.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Computer & Office",
    "sub_cat": "Security & Protection",
    "low_cat": "Fire Protection",
    "merci_image": "img/categories/home-5/10.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Computer & Office",
    "sub_cat": "Security & Protection",
    "low_cat": "Workplace Safety Supplies",
    "merci_image": "img/categories/home-5/10.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Computer & Office",
    "sub_cat": "Security & Protection",
    "low_cat": "Door Intercom",
    "merci_image": "img/categories/home-5/10.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Computer & Office",
    "sub_cat": "Security & Protection",
    "low_cat": "Surveillance Products",
    "merci_image": "img/categories/home-5/10.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Computer & Office",
    "sub_cat": "Storage Devices",
    "low_cat": "SSD",
    "merci_image": "img/categories/home-5/10.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Computer & Office",
    "sub_cat": "Storage Devices",
    "low_cat": "USB Flash Drives",
    "merci_image": "img/categories/home-5/10.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Computer & Office",
    "sub_cat": "Storage Devices",
    "low_cat": "HDD Enclosures",
    "merci_image": "img/categories/home-5/10.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Computer & Office",
    "sub_cat": "Storage Devices",
    "low_cat": "Memory Cards",
    "merci_image": "img/categories/home-5/10.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Computer & Office",
    "sub_cat": "Storage Devices",
    "low_cat": "External Hard Drives",
    "merci_image": "img/categories/home-5/10.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Computer & Office",
    "sub_cat": "Tablet & Laptop Accessories",
    "low_cat": "Tablet LCD Screens",
    "merci_image": "img/categories/home-5/10.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Computer & Office",
    "sub_cat": "Tablet & Laptop Accessories",
    "low_cat": "Laptop Batteries",
    "merci_image": "img/categories/home-5/10.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Computer & Office",
    "sub_cat": "Tablet & Laptop Accessories",
    "low_cat": "Laptop Bags & Cases",
    "merci_image": "img/categories/home-5/10.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Computer & Office",
    "sub_cat": "Tablet & Laptop Accessories",
    "low_cat": "Tablet Cases",
    "merci_image": "img/categories/home-5/10.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Computer & Office",
    "sub_cat": "Tablet & Laptop Accessories",
    "low_cat": "Tablet Accessories",
    "merci_image": "img/categories/home-5/10.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Computer & Office",
    "sub_cat": "Networking",
    "low_cat": "Modem-Router Combos",
    "merci_image": "img/categories/home-5/10.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Computer & Office",
    "sub_cat": "Networking",
    "low_cat": "Wireless Routers",
    "merci_image": "img/categories/home-5/10.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Computer & Office",
    "sub_cat": "Networking",
    "low_cat": "Networking Tools",
    "merci_image": "img/categories/home-5/10.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Computer & Office",
    "sub_cat": "Networking",
    "low_cat": "3G Modems",
    "merci_image": "img/categories/home-5/10.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Computer & Office",
    "sub_cat": "Networking",
    "low_cat": "Network Cards",
    "merci_image": "img/categories/home-5/10.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Bags & Shoes",
    "sub_cat": "Men's Luggage & Bags",
    "low_cat": "Briefcases",
    "merci_image": "img/categories/clothing/4.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Bags & Shoes",
    "sub_cat": "Men's Luggage & Bags",
    "low_cat": "Waist Bags",
    "merci_image": "img/categories/clothing/4.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Bags & Shoes",
    "sub_cat": "Men's Luggage & Bags",
    "low_cat": "Girls Bags",
    "merci_image": "img/categories/clothing/4.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Bags & Shoes",
    "sub_cat": "Men's Luggage & Bags",
    "low_cat": "Men's Backpacks",
    "merci_image": "img/categories/clothing/4.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Bags & Shoes",
    "sub_cat": "Men's Luggage & Bags",
    "low_cat": "Luggage & Travel Bags",
    "merci_image": "img/categories/clothing/4.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Bags & Shoes",
    "sub_cat": "Men's Luggage & Bags",
    "low_cat": "Crossbody Bags",
    "merci_image": "img/categories/clothing/4.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Bags & Shoes",
    "sub_cat": "Men's Luggage & Bags",
    "low_cat": "Man Wallets",
    "merci_image": "img/categories/clothing/4.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Bags & Shoes",
    "sub_cat": "Women's Shoes",
    "low_cat": "Woman Boots",
    "merci_image": "img/categories/clothing/4.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Bags & Shoes",
    "sub_cat": "Women's Shoes",
    "low_cat": "Vulcanize Shoes",
    "merci_image": "img/categories/clothing/4.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Bags & Shoes",
    "sub_cat": "Women's Shoes",
    "low_cat": "Pumps",
    "merci_image": "img/categories/clothing/4.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Bags & Shoes",
    "sub_cat": "Women's Shoes",
    "low_cat": "Woman Slippers",
    "merci_image": "img/categories/clothing/4.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Bags & Shoes",
    "sub_cat": "Women's Shoes",
    "low_cat": "Woman Sandals",
    "merci_image": "img/categories/clothing/4.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Bags & Shoes",
    "sub_cat": "Women's Shoes",
    "low_cat": "Flats",
    "merci_image": "img/categories/clothing/4.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Bags & Shoes",
    "sub_cat": "Women's Luggage & Bags",
    "low_cat": "Woman Wallets",
    "merci_image": "img/categories/clothing/4.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Bags & Shoes",
    "sub_cat": "Women's Luggage & Bags",
    "low_cat": "Evening Bags",
    "merci_image": "img/categories/clothing/4.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Bags & Shoes",
    "sub_cat": "Women's Luggage & Bags",
    "low_cat": "Fashion Backpacks",
    "merci_image": "img/categories/clothing/4.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Bags & Shoes",
    "sub_cat": "Women's Luggage & Bags",
    "low_cat": "Shoulder Bags",
    "merci_image": "img/categories/clothing/4.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Bags & Shoes",
    "sub_cat": "Women's Luggage & Bags",
    "low_cat": "Totes",
    "merci_image": "img/categories/clothing/4.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Bags & Shoes",
    "sub_cat": "Women's Luggage & Bags",
    "low_cat": "Boys Bags",
    "merci_image": "img/categories/clothing/4.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Bags & Shoes",
    "sub_cat": "Women's Luggage & Bags",
    "low_cat": "Clutches",
    "merci_image": "img/categories/clothing/4.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Bags & Shoes",
    "sub_cat": "Men's Shoes",
    "low_cat": "Man Boots",
    "merci_image": "img/categories/clothing/4.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Bags & Shoes",
    "sub_cat": "Men's Shoes",
    "low_cat": "Formal Shoes",
    "merci_image": "img/categories/clothing/4.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Bags & Shoes",
    "sub_cat": "Men's Shoes",
    "low_cat": "Man Slippers",
    "merci_image": "img/categories/clothing/4.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Bags & Shoes",
    "sub_cat": "Men's Shoes",
    "low_cat": "Vulcanize Shoe",
    "merci_image": "img/categories/clothing/4.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Bags & Shoes",
    "sub_cat": "Men's Shoes",
    "low_cat": "Man Sandals",
    "merci_image": "img/categories/clothing/4.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Bags & Shoes",
    "sub_cat": "Men's Shoes",
    "low_cat": "Casual Shoes",
    "merci_image": "img/categories/clothing/4.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Wedding & Engagement",
    "low_cat": "Bridal Jewelry Sets",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Wedding & Engagement",
    "low_cat": "Wedding Hair Jewelry",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Wedding & Engagement",
    "low_cat": "Engagement Rings",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Wedding & Engagement",
    "low_cat": "Wedding & Engagement",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Fashion Jewelry",
    "low_cat": "Bracelets & Bangles",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Fashion Jewelry",
    "low_cat": "Brooches",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Fashion Jewelry",
    "low_cat": "Keychains",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Fashion Jewelry",
    "low_cat": "Charms",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Fashion Jewelry",
    "low_cat": "Rings",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Fashion Jewelry",
    "low_cat": "Body Jewelry",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Fashion Jewelry",
    "low_cat": "Fashion Jewelry Sets",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Fashion Jewelry",
    "low_cat": "Necklace & Pendants",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Fashion Jewelry",
    "low_cat": "Men's Cuff Links",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Fashion Jewelry",
    "low_cat": "Earrings",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Fine Jewelry",
    "low_cat": "Various Gemstones",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Fine Jewelry",
    "low_cat": "925 Silver Jewelry",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Fine Jewelry",
    "low_cat": "K-Gold",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Fine Jewelry",
    "low_cat": "Pearls Jewelry",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Fine Jewelry",
    "low_cat": "Fine Earrings",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Fine Jewelry",
    "low_cat": "Men's Fine Jewelry",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Fine Jewelry",
    "low_cat": "Fine Jewelry Sets",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Men's Watches",
    "low_cat": "Quartz Watches",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Men's Watches",
    "low_cat": "Mechanical Watches",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Men's Watches",
    "low_cat": "Digital Watches",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Men's Watches",
    "low_cat": "Dual Display Watches",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Men's Watches",
    "low_cat": "Men Sports Watches",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Women's Watches",
    "low_cat": "Women Sports Watches",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Women's Watches",
    "low_cat": "Dress Watches",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Women's Watches",
    "low_cat": "Creative Watches",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Women's Watches",
    "low_cat": "Lovers' Watches",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Women's Watches",
    "low_cat": "Women's Bracelet Watches",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Jewelry & Watches",
    "sub_cat": "Women's Watches",
    "low_cat": "Children's Watches",
    "merci_image": "img/categories/home-5/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Health, Beauty & Hair",
    "sub_cat": "Nail Art & Tools",
    "low_cat": "Nail Glitters",
    "merci_image": "img/categories/home-5/8.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Health, Beauty & Hair",
    "sub_cat": "Nail Art & Tools",
    "low_cat": "Stickers & Decals",
    "merci_image": "img/categories/home-5/8.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Health, Beauty & Hair",
    "sub_cat": "Nail Art & Tools",
    "low_cat": "Nail Decorations",
    "merci_image": "img/categories/home-5/8.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Health, Beauty & Hair",
    "sub_cat": "Nail Art & Tools",
    "low_cat": "Nail Gel",
    "merci_image": "img/categories/home-5/8.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Health, Beauty & Hair",
    "sub_cat": "Nail Art & Tools",
    "low_cat": "Nail Dryers",
    "merci_image": "img/categories/home-5/8.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Health, Beauty & Hair",
    "sub_cat": "Nail Art & Tools",
    "low_cat": "Nail Art Kits",
    "merci_image": "img/categories/home-5/8.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Health, Beauty & Hair",
    "sub_cat": "Skin Care",
    "low_cat": "Razor",
    "merci_image": "img/categories/home-5/8.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Health, Beauty & Hair",
    "sub_cat": "Skin Care",
    "low_cat": "Face Masks",
    "merci_image": "img/categories/home-5/8.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Health, Beauty & Hair",
    "sub_cat": "Skin Care",
    "low_cat": "Sun Care",
    "merci_image": "img/categories/home-5/8.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Health, Beauty & Hair",
    "sub_cat": "Skin Care",
    "low_cat": "Essential Oil",
    "merci_image": "img/categories/home-5/8.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Health, Beauty & Hair",
    "sub_cat": "Skin Care",
    "low_cat": "Body Care",
    "merci_image": "img/categories/home-5/8.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Health, Beauty & Hair",
    "sub_cat": "Skin Care",
    "low_cat": "Facial Care",
    "merci_image": "img/categories/home-5/8.jpg"
  },
  // {
  //   "company": "cj",
  //   "main_cat": "Health, Beauty & Hair",
  //   "sub_cat": "Hair Weaves",
  //   "low_cat": "Pre-Colored One Pack",
  //   "merci_image": "img/categories/home-5/8.jpg"
  // },
  // {
  //   "company": "cj",
  //   "main_cat": "Health, Beauty & Hair",
  //   "sub_cat": "Hair Weaves",
  //   "low_cat": "Hair Weaving",
  //   "merci_image": "img/categories/home-5/8.jpg"
  // },
  // {
  //   "company": "cj",
  //   "main_cat": "Health, Beauty & Hair",
  //   "sub_cat": "Hair Weaves",
  //   "low_cat": "Hair Styling",
  //   "merci_image": "img/categories/home-5/8.jpg"
  // },
  // {
  //   "company": "cj",
  //   "main_cat": "Health, Beauty & Hair",
  //   "sub_cat": "Hair Weaves",
  //   "low_cat": "Salon Bundle Hair",
  //   "merci_image": "img/categories/home-5/8.jpg"
  // },
  // {
  //   "company": "cj",
  //   "main_cat": "Health, Beauty & Hair",
  //   "sub_cat": "Hair Weaves",
  //   "low_cat": "Pre-Colored Hair Weave",
  //   "merci_image": "img/categories/home-5/8.jpg"
  // },
  {
    "company": "cj",
    "main_cat": "Health, Beauty & Hair",
    "sub_cat": "Makeup",
    "low_cat": "Makeup Set",
    "merci_image": "img/categories/home-5/8.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Health, Beauty & Hair",
    "sub_cat": "Makeup",
    "low_cat": "Eyeshadow",
    "merci_image": "img/categories/home-5/8.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Health, Beauty & Hair",
    "sub_cat": "Makeup",
    "low_cat": "Makeup Brushes",
    "merci_image": "img/categories/home-5/8.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Health, Beauty & Hair",
    "sub_cat": "Makeup",
    "low_cat": "Lipstick",
    "merci_image": "img/categories/home-5/8.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Health, Beauty & Hair",
    "sub_cat": "Makeup",
    "low_cat": "False Eyelashes",
    "merci_image": "img/categories/home-5/8.jpg"
  },
  // {
  //   "company": "cj",
  //   "main_cat": "Health, Beauty & Hair",
  //   "sub_cat": "Wigs & Extensions",
  //   "low_cat": "Human Hair Wigs",
  //   "merci_image": "img/categories/home-5/8.jpg"
  // },
  // {
  //   "company": "cj",
  //   "main_cat": "Health, Beauty & Hair",
  //   "sub_cat": "Wigs & Extensions",
  //   "low_cat": "Synthetic Hair Pieces",
  //   "merci_image": "img/categories/home-5/8.jpg"
  // },
  // {
  //   "company": "cj",
  //   "main_cat": "Health, Beauty & Hair",
  //   "sub_cat": "Wigs & Extensions",
  //   "low_cat": "Synthetic Lace Wigs",
  //   "merci_image": "img/categories/home-5/8.jpg"
  // },
  // {
  //   "company": "cj",
  //   "main_cat": "Health, Beauty & Hair",
  //   "sub_cat": "Wigs & Extensions",
  //   "low_cat": "Human Hair Lace Wigs",
  //   "merci_image": "img/categories/home-5/8.jpg"
  // },
  // {
  //   "company": "cj",
  //   "main_cat": "Health, Beauty & Hair",
  //   "sub_cat": "Wigs & Extensions",
  //   "low_cat": "Hair Braids",
  //   "merci_image": "img/categories/home-5/8.jpg"
  // },
  // {
  //   "company": "cj",
  //   "main_cat": "Health, Beauty & Hair",
  //   "sub_cat": "Wigs & Extensions",
  //   "low_cat": "Synthetic Wigs",
  //   "merci_image": "img/categories/home-5/8.jpg"
  // },
  {
    "company": "cj",
    "main_cat": "Health, Beauty & Hair",
    "sub_cat": "Beauty Tools",
    "low_cat": "Straightening Irons",
    "merci_image": "img/categories/home-5/8.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Health, Beauty & Hair",
    "sub_cat": "Beauty Tools",
    "low_cat": "Electric Face Cleanser",
    "merci_image": "img/categories/home-5/8.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Health, Beauty & Hair",
    "sub_cat": "Beauty Tools",
    "low_cat": "Face Skin Care Tools",
    "merci_image": "img/categories/home-5/8.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Health, Beauty & Hair",
    "sub_cat": "Beauty Tools",
    "low_cat": "Curling Iron",
    "merci_image": "img/categories/home-5/8.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Health, Beauty & Hair",
    "sub_cat": "Beauty Tools",
    "low_cat": "Facial Steamer",
    "merci_image": "img/categories/home-5/8.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Accessories",
    "low_cat": "Scarves & Wraps",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Accessories",
    "low_cat": "Face Masks",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Accessories",
    "low_cat": "Belts & Cummerbunds",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Accessories",
    "low_cat": "Woman Gloves & Mittens",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Accessories",
    "low_cat": "Woman Socks",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Accessories",
    "low_cat": "Woman Hats & Caps",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Outerwear & Jackets",
    "low_cat": "Blazers",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Outerwear & Jackets",
    "low_cat": "Wool & Blend",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Outerwear & Jackets",
    "low_cat": "Woman Trench",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Outerwear & Jackets",
    "low_cat": "Basic Jacket",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Outerwear & Jackets",
    "low_cat": "Leather & Suede",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Outerwear & Jackets",
    "low_cat": "Real Fur",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Tops & Sets",
    "low_cat": "Ladies Short Sleeve",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Tops & Sets",
    "low_cat": "Blouses & Shirts",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Tops & Sets",
    "low_cat": "Woman Hoodies & Sweatshirts",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Tops & Sets",
    "low_cat": "Jumpsuits",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Tops & Sets",
    "low_cat": "Rompers",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Tops & Sets",
    "low_cat": "Lady Dresses",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Tops & Sets",
    "low_cat": "Sweaters",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Tops & Sets",
    "low_cat": "Suits & Sets",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Weddings & Events",
    "low_cat": "Cocktail Dresses",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Weddings & Events",
    "low_cat": "Evening Dresses",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Weddings & Events",
    "low_cat": "Bridesmaid Dresses",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Weddings & Events",
    "low_cat": "Prom Dresses",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Weddings & Events",
    "low_cat": "Wedding Dresses",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Weddings & Events",
    "low_cat": "Flower Girl Dresses",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Bottoms",
    "low_cat": "Leggings",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Bottoms",
    "low_cat": "Skirts",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Bottoms",
    "low_cat": "Woman Jeans",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Bottoms",
    "low_cat": "Woman Shorts",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Bottoms",
    "low_cat": "Pants & Capris",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Women's Clothing",
    "sub_cat": "Bottoms",
    "low_cat": "Wide Leg Pants",
    "merci_image": "img/categories/clothing/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Sports & Outdoors",
    "sub_cat": "Sneakers",
    "low_cat": "Running Shoes",
    "merci_image": "img/categories/clothing/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Sports & Outdoors",
    "sub_cat": "Sneakers",
    "low_cat": "Dance Shoes",
    "merci_image": "img/categories/clothing/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Sports & Outdoors",
    "sub_cat": "Sneakers",
    "low_cat": "Skateboarding Shoes",
    "merci_image": "img/categories/clothing/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Sports & Outdoors",
    "sub_cat": "Sneakers",
    "low_cat": "Hiking Shoes",
    "merci_image": "img/categories/clothing/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Sports & Outdoors",
    "sub_cat": "Sneakers",
    "low_cat": "Soccer Shoes",
    "merci_image": "img/categories/clothing/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Sports & Outdoors",
    "sub_cat": "Sneakers",
    "low_cat": "Basketball Shoes",
    "merci_image": "img/categories/clothing/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Sports & Outdoors",
    "sub_cat": "Cycling",
    "low_cat": "Bicycle Lights",
    "merci_image": "img/categories/clothing/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Sports & Outdoors",
    "sub_cat": "Cycling",
    "low_cat": "Bicycle Helmets",
    "merci_image": "img/categories/clothing/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Sports & Outdoors",
    "sub_cat": "Cycling",
    "low_cat": "Cycling Eyewear",
    "merci_image": "img/categories/clothing/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Sports & Outdoors",
    "sub_cat": "Cycling",
    "low_cat": "Bicycle Frames",
    "merci_image": "img/categories/clothing/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Sports & Outdoors",
    "sub_cat": "Cycling",
    "low_cat": "Cycling Jerseys",
    "merci_image": "img/categories/clothing/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Sports & Outdoors",
    "sub_cat": "Cycling",
    "low_cat": "Bicycles",
    "merci_image": "img/categories/clothing/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Home Textiles",
    "low_cat": "Curtains",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Home Textiles",
    "low_cat": "Cushion Covers",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Home Textiles",
    "low_cat": "Towels",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Home Textiles",
    "low_cat": "Comforters",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Home Textiles",
    "low_cat": "Bedding Sets",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Home Textiles",
    "low_cat": "Pillows",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Pet Products",
    "low_cat": "Dog Supplies",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Pet Products",
    "low_cat": "Bird Supplies",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Pet Products",
    "low_cat": "Cat Supplies",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Pet Products",
    "low_cat": "Fish & Aquatic Pets",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Pet Products",
    "low_cat": "Dog Clothing & Shoes",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Pet Products",
    "low_cat": "Dog Collars, Harnesses & Leads",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Kitchen, Dining & Bar",
    "low_cat": "Dinnerware",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Kitchen, Dining & Bar",
    "low_cat": "Kitchen Knives & Accessories",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Kitchen, Dining & Bar",
    "low_cat": "Bakeware",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Kitchen, Dining & Bar",
    "low_cat": "Barware",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Kitchen, Dining & Bar",
    "low_cat": "Drinkware",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Kitchen, Dining & Bar",
    "low_cat": "Cooking Tools",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Home Storage",
    "low_cat": "Kitchen Storage",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Home Storage",
    "low_cat": "Home Office Storage",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Home Storage",
    "low_cat": "Clothing & Wardrobe Storage",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Home Storage",
    "low_cat": "Bathroom Storage",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Home Storage",
    "low_cat": "Storage Bottles & Jars",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "LED Lighting",
    "low_cat": "LED Spotlights",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Home Appliances",
    "low_cat": "Personal Care Appliances",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Home Appliances",
    "low_cat": "Cleaning Appliances",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Home Appliances",
    "low_cat": "Air Conditioning Appliances",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Home Appliances",
    "low_cat": "Home Appliance Parts",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Home Appliances",
    "low_cat": "Kitchen Appliances",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Outdoor Lighting",
    "low_cat": "Flashlights & Torches",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Outdoor Lighting",
    "low_cat": "Floodlights",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Outdoor Lighting",
    "low_cat": "Underwater Lights",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Outdoor Lighting",
    "low_cat": "String Lights",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Home, Garden & Furniture",
    "sub_cat": "Outdoor Lighting",
    "low_cat": "Solar Lamps",
    "merci_image": "img/categories/furniture/1.png"
  },
  {
    "company": "cj",
    "main_cat": "Automobiles & Motorcycles",
    "sub_cat": "Car Electronics",
    "low_cat": "Vehicle Camera",
    "merci_image": "img/categories/home-2/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Automobiles & Motorcycles",
    "sub_cat": "Car Electronics",
    "low_cat": "DVR & Dash Camera",
    "merci_image": "img/categories/home-2/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Automobiles & Motorcycles",
    "sub_cat": "Car Electronics",
    "low_cat": "Car Monitors",
    "merci_image": "img/categories/home-2/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Automobiles & Motorcycles",
    "sub_cat": "Car Electronics",
    "low_cat": "Vehicle GPS",
    "merci_image": "img/categories/home-2/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Automobiles & Motorcycles",
    "sub_cat": "Car Electronics",
    "low_cat": "Car Mirror Video",
    "merci_image": "img/categories/home-2/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Automobiles & Motorcycles",
    "sub_cat": "Car Electronics",
    "low_cat": "Car Radios",
    "merci_image": "img/categories/home-2/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Automobiles & Motorcycles",
    "sub_cat": "Car Electronics",
    "low_cat": "GPS Trackers",
    "merci_image": "img/categories/home-2/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Automobiles & Motorcycles",
    "sub_cat": "Car Electronics",
    "low_cat": "Car Multimedia Player",
    "merci_image": "img/categories/home-2/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Automobiles & Motorcycles",
    "sub_cat": "Car Electronics",
    "low_cat": "Alarm Systems & Security",
    "merci_image": "img/categories/home-2/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Automobiles & Motorcycles",
    "sub_cat": "Car Electronics",
    "low_cat": "Jump Starter",
    "merci_image": "img/categories/home-2/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Automobiles & Motorcycles",
    "sub_cat": "Interior Accessories",
    "low_cat": "Floor Mats",
    "merci_image": "img/categories/home-2/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Automobiles & Motorcycles",
    "sub_cat": "Interior Accessories",
    "low_cat": "Key Case for Car",
    "merci_image": "img/categories/home-2/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Automobiles & Motorcycles",
    "sub_cat": "Interior Accessories",
    "low_cat": "Steering Covers",
    "merci_image": "img/categories/home-2/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Automobiles & Motorcycles",
    "sub_cat": "Interior Accessories",
    "low_cat": "Automobiles Seat Covers",
    "merci_image": "img/categories/home-2/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Automobiles & Motorcycles",
    "sub_cat": "Interior Accessories",
    "low_cat": "Stowing Tidying",
    "merci_image": "img/categories/home-2/3.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Toys & Hobbies",
    "low_cat": "Electronic Pets",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Toys & Hobbies",
    "low_cat": "Blocks",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Toys & Hobbies",
    "low_cat": "RC Helicopters",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Toys & Hobbies",
    "low_cat": "Stuffed & Plush Animals",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Toys & Hobbies",
    "low_cat": "Action & Toy Figures",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Baby Clothing",
    "low_cat": "Baby Clothing Sets",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Baby Clothing",
    "low_cat": "Baby Rompers",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Baby Clothing",
    "low_cat": "Baby Accessories",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Baby Clothing",
    "low_cat": "Baby Outerwear",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Baby Clothing",
    "low_cat": "Baby Dresses",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Baby Clothing",
    "low_cat": "Baby Pants",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Shoes & Bags",
    "low_cat": "Boys Shoes",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Shoes & Bags",
    "low_cat": "School Bags",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Shoes & Bags",
    "low_cat": "Kids Wallets",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Shoes & Bags",
    "low_cat": "Girls Shoes",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Shoes & Bags",
    "low_cat": "Baby's First Walkers",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Baby & Mother",
    "low_cat": "Nappy Changing",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Baby & Mother",
    "low_cat": "Activity & Gear",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Baby & Mother",
    "low_cat": "Backpacks & Carriers",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Baby & Mother",
    "low_cat": "Baby Care",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Baby & Mother",
    "low_cat": "Maternity",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Boys Clothing",
    "low_cat": "Boy Jeans",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Boys Clothing",
    "low_cat": "Boy T-Shirts",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Boys Clothing",
    "low_cat": "Boy Hoodies & Sweatshirts",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Boys Clothing",
    "low_cat": "Outerwear & Coats",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Boys Clothing",
    "low_cat": "Boy Clothing Sets",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Boys Clothing",
    "low_cat": "Boy Accessories",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Girls Clothing",
    "low_cat": "Girls Underwear",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Girls Clothing",
    "low_cat": "Family Matching Outfits",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Girls Clothing",
    "low_cat": "Sleepwear & Robes",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Girls Clothing",
    "low_cat": "Tops & Tees",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Girls Clothing",
    "low_cat": "Girl Clothing Sets",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Girls Clothing",
    "low_cat": "Girl Accessories",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Toys, Kids & Babies",
    "sub_cat": "Girls Clothing",
    "low_cat": "Girl Dresses",
    "merci_image": "img/categories/home-kids/7.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Underwear & Loungewear",
    "low_cat": "Men's Sleep & Lounge",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Underwear & Loungewear",
    "low_cat": "Shorts",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Underwear & Loungewear",
    "low_cat": "Briefs",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Underwear & Loungewear",
    "low_cat": "Robes",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Underwear & Loungewear",
    "low_cat": "Man Pajama Sets",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Underwear & Loungewear",
    "low_cat": "Boxers",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Underwear & Loungewear",
    "low_cat": "Long Johns",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Bottoms",
    "low_cat": "Pajama Sets",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Bottoms",
    "low_cat": "Man Shorts",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Bottoms",
    "low_cat": "Cargo Pants",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Bottoms",
    "low_cat": "Man Jeans",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Bottoms",
    "low_cat": "Harem Pants",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Bottoms",
    "low_cat": "Casual Pants",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Bottoms",
    "low_cat": "Sweatpants",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Outerwear & Jackets",
    "low_cat": "Suits & Blazer",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Outerwear & Jackets",
    "low_cat": "Men's Sweaters",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Outerwear & Jackets",
    "low_cat": "Genuine Leather",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Outerwear & Jackets",
    "low_cat": "Man Trench",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Outerwear & Jackets",
    "low_cat": "Man Hoodies & Sweatshirts",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Outerwear & Jackets",
    "low_cat": "Wool & Blends",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Outerwear & Jackets",
    "low_cat": "Parkas",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Outerwear & Jackets",
    "low_cat": "Down Jackets",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "T-Shirts",
    "low_cat": "Geometric",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "T-Shirts",
    "low_cat": "Striped",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "T-Shirts",
    "low_cat": "Solid",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "T-Shirts",
    "low_cat": "3D",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "T-Shirts",
    "low_cat": "Print",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Accessories",
    "low_cat": "Socks",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Accessories",
    "low_cat": "Scarves",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Accessories",
    "low_cat": "Man Gloves & Mittens",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Accessories",
    "low_cat": "Skullies & Beanies",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Accessories",
    "low_cat": "Belts",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Accessories",
    "low_cat": "Man Prescription Glasses",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Hats & Caps",
    "low_cat": "Baseball Caps",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Hats & Caps",
    "low_cat": "Bomber Hats",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Hats & Caps",
    "low_cat": "Berets",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Men's Clothing",
    "sub_cat": "Hats & Caps",
    "low_cat": "Fedoras",
    "merci_image": "img/categories/shop/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Accessories & Parts",
    "low_cat": "Digital Cables",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Accessories & Parts",
    "low_cat": "Home Electronic Accessories",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Accessories & Parts",
    "low_cat": "Audio & Video Cables",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Accessories & Parts",
    "low_cat": "Charger",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Accessories & Parts",
    "low_cat": "Batteries",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Accessories & Parts",
    "low_cat": "Digital Gear Bags",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Camera & Photo",
    "low_cat": "Photo Studio",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Camera & Photo",
    "low_cat": "Camera Drones",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Camera & Photo",
    "low_cat": "Camera & Photo Accessories",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Camera & Photo",
    "low_cat": "Digital Cameras",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Camera & Photo",
    "low_cat": "Action Cameras",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Camera & Photo",
    "low_cat": "Camcorders",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Home Audio & Video",
    "low_cat": "Projectors",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Home Audio & Video",
    "low_cat": "Television",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Home Audio & Video",
    "low_cat": "TV Receivers",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Home Audio & Video",
    "low_cat": "Audio Amplifiers",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Home Audio & Video",
    "low_cat": "Projectors & Acessories",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Home Audio & Video",
    "low_cat": "Home Audio & Video",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Home Audio & Video",
    "low_cat": "TV Sticks",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Video Games",
    "low_cat": "Gamepads",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Video Games",
    "low_cat": "Handheld Game Players",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Video Games",
    "low_cat": "Video Game Consoles",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Video Games",
    "low_cat": "Stickers",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Video Games",
    "low_cat": "Joysticks",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Smart Electronics",
    "low_cat": "Wearable Devices",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Smart Electronics",
    "low_cat": "Smart Home Appliances",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Smart Electronics",
    "low_cat": "Smart Wearable Accessories",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Smart Electronics",
    "low_cat": "Smart Wristbands",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Smart Electronics",
    "low_cat": "Smart Watches",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Smart Electronics",
    "low_cat": "Smart Remote Controls",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Portable Audio & Video",
    "low_cat": "Microphones",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Portable Audio & Video",
    "low_cat": "Speakers",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Portable Audio & Video",
    "low_cat": "Earphones & Headphones",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Portable Audio & Video",
    "low_cat": "VR & AR Devices",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Consumer Electronics",
    "sub_cat": "Portable Audio & Video",
    "low_cat": "MP3 Players",
    "merci_image": "img/categories/shop/5.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Mobile Phone Accessories",
    "low_cat": "Cables",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Mobile Phone Accessories",
    "low_cat": "Power Bank",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Mobile Phone Accessories",
    "low_cat": "Screen Protectors",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Mobile Phone Accessories",
    "low_cat": "Lenses",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Mobile Phone Accessories",
    "low_cat": "Holders & Stands",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Mobile Phone Accessories",
    "low_cat": "Chargers",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Cases & Covers",
    "low_cat": "Huawei Cases",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Cases & Covers",
    "low_cat": "Patterned Cases",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Cases & Covers",
    "low_cat": "Cases For iPhone 6 & 6 Plus",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Cases & Covers",
    "low_cat": "Wallet Cases",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Cases & Covers",
    "low_cat": "Cases For iPhone 7 & 7 Plus",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Cases & Covers",
    "low_cat": "Galaxy S8 Cases",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Cases & Covers",
    "low_cat": "IPhone X Cases",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Cases & Covers",
    "low_cat": "Galaxy S7 Cases",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Cases & Covers",
    "low_cat": "Cases For iPhone 8 & 8 Plus",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Cases & Covers",
    "low_cat": "Xiaomi Cases",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Cases & Covers",
    "low_cat": "Silicone Cases",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Cases & Covers",
    "low_cat": "Flip Cases",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Cases & Covers",
    "low_cat": "Waterptoof Cases",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Cases & Covers",
    "low_cat": "Leather Cases",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Mobile Phone Parts",
    "low_cat": "SIM Card & Tools",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Mobile Phone Parts",
    "low_cat": "Mobile Batteries",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Mobile Phone Parts",
    "low_cat": "Housings",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Mobile Phone Parts",
    "low_cat": "LCDs",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Mobile Phone Parts",
    "low_cat": "Flex Cables",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Mobile Phone Parts",
    "low_cat": "Touch Panel",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Mobile Phones",
    "low_cat": "Quad Core",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Mobile Phones",
    "low_cat": "Single SIM Card",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Mobile Phones",
    "low_cat": "Dual SIM Card",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Mobile Phones",
    "low_cat": "3GB RAM",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Mobile Phones",
    "low_cat": "Octa Core",
    "merci_image": "img/categories/technology/1.jpg"
  },
  {
    "company": "cj",
    "main_cat": "Phones & Accessories",
    "sub_cat": "Mobile Phones",
    "low_cat": "5-inch Display",
    "merci_image": "img/categories/technology/1.jpg"
  }
]

export const IndianMenuCSS = [
  {
    "cat": "Computer & Office",
    "cssVal": "left",
  },
  {
    "cat": "Bags & Shoes",
    "cssVal": "left",
  },
  {
    "cat": "Jewelry & Watches",
    "cssVal": "left",
  },
  {
    "cat": "Health, Beauty & Hair",
    "cssVal": "left",
  },
  {
    "cat": "Women's Clothing",
    "cssVal": "right",
  },
  {
    "cat": "Sports & Outdoors",
    "cssVal": "right",
  },
  {
    "cat": "Home, Garden & Furniture",
    "cssVal": "right",
  },
  {
    "cat": "Automobiles & Motorcycles",
    "cssVal": "left",
  },
  {
    "cat": "Toys, Kids & Babies",
    "cssVal": "left",
  },
  {
    "cat": "Men's Clothing",
    "cssVal": "left",
  },
  {
    "cat": "Consumer Electronics",
    "cssVal": "right",
  },
  {
    "cat": "Phones & Accessories",
    "cssVal": "right",
  }
]