import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    wishlist: []
};

export const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState,
  reducers: {
    updateWishlist: (state, action) => {
      if (Array.isArray(action.payload) && action.payload.length === 0) {
        state.wishlist = [];
      } else {
      if(!state.wishlist.some(wish => wish.merci_prod_name === action.payload.merci_prod_name)){
      state.wishlist = [...state.wishlist, action.payload];
      }
    }
    },
    deleteWishlist: (state, action) => {
      state.wishlist = state.wishlist.filter(item => item.id !== action.payload);
    },
  }
});
export const { updateWishlist, deleteWishlist } = wishlistSlice.actions;
export default wishlistSlice.reducer;
