import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const About = () => {

  useEffect(() => { window.scrollTo(0, 0); }, []);
  return (
    <section className="listingPage">
      <div className="container-fluid">
        <div className="breadcrumb flex-column">
          <h1 className="text-capitalize">About Merci Emart</h1>
          <ul className="list list-inline mb-0">
            <li className="list-inline-item">
              <Link to={"/"}>Home</Link>
            </li>
          </ul>
        </div>
        <div className="w-90" style={{textAlign: 'center'}}>
                <div className="ps-section__header">
                    <h4>Welcome to Merci E-Mart</h4>
                    <h3>We connect millions of buyers and sellers around the world, empowering people & creating economic opportunity for all.</h3>
                    <p>Within our markets, millions of people around the world connect, both online and offline, to make, sell and buy unique goods. We also offer a wide range of Seller Services and tools that help creative entrepreneurs start, manage and scale their businesses. Our mission is to reimagine commerce in ways that build a more fulfilling and lasting world, and we’re committed to using the power of business to strengthen communities and empower people.</p>
                </div>
                <div className="ps-section__content">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6 ">
                            <div className="ps-block--icon-box"><i className="icon-cube"></i>
                                <h4>45K</h4>
                                <p>Product for sale</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6 ">
                            <div className="ps-block--icon-box"><i className="icon-store"></i>
                                <h4>1.8K</h4>
                                <p>Sellers Active on Merci E-Mart</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6 ">
                            <div className="ps-block--icon-box"><i className="icon-bag2"></i>
                                <h4>30K</h4>
                                <p>Buyer active on Merci E-Mart</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6 ">
                            <div className="ps-block--icon-box"><i className="icon-cash-dollar"></i>
                                <h4>Rs 2 M</h4>
                                <p>Annual gross merchandise sales</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </div>
    </section>
  );
};

export default About;
