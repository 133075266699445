import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./style.css";
import TextField from "@mui/material/TextField";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { Button } from "@mui/material";
import { useState } from "react";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { checkPhoneNumber, createUser } from "../../api/userApi";

const SignUp = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [phoneAvailable, setPhoneAvailable] = useState("");
  const [passAvailable, setPassAvailable] = useState("");
  const [confirmPassAvailable, setConfirmPassAvailable] = useState("");
  const navigate = useNavigate();

  const [showLoader, setShowLoader] = useState(false);
  const [formFields, setFormFields] = useState({
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    street_one: "",
    street_two: "",
    district: "",
    state: "",
    pincode: "",
    gender: "",
    dob: "",
  });

  const onPasswordValidCheck = () => {
    // Minimum eight characters, one uppercase letter, and one alphanumeric number
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[a-zA-Z\d@$!%*?&]{8,}$/;

    if (!passwordRegex.test(formFields.password)) {
      setPassAvailable("Invalid Password!");
      return false;
    }
    setPassAvailable("");
    return true;
  };

  const onCheckWithPhone = async () => {
    const phonechk = await checkPhoneNumber(formFields.phone);
    if (phonechk.status === 400) {
      return setPhoneAvailable(phonechk.message);
    }
    return setPhoneAvailable("");
  };

  const onsignUp = async () => {
    if (
      formFields.fullName !== "" &&
      formFields.phone !== "" &&
      formFields.email !== "" &&
      formFields.password !== "" &&
      formFields.confirmPassword !== ""
    ) {
      setShowLoader(true);
      if (
        onPasswordValidCheck ||
        formFields.password === formFields.confirmPassword
      ) {
        setConfirmPassAvailable("");
        delete formFields.confirmPassword;
        const addedUser = await createUser(formFields);
        if (addedUser.status === 200) {
          alert("Your Registration has been done. Please Login");
          // onChangeAddUser();
          setFormFields({
            fullName: "",
            phone: "",
            email: "",
            password: "",
            confirmPassword: "",
          });
          setShowLoader(false);
          navigate('/SignIn');
        } else {
          alert("Please Try again, there is an issue creating the Member");
        }
      } else {
        alert("Please fill all the details");
      }
    }
  };

  const onChangeField = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setFormFields(() => ({
      ...formFields,
      [name]: value,
    }));
  };

  return (
    <>
      <section className="signIn mb-5">
        <div class="breadcrumbWrapper res-hide">
          <div class="container-fluid">
            <ul class="breadcrumb breadcrumb2 mb-0">
              <li>
                <Link to="/">Home</Link>{" "}
              </li>
              <li>SignUp</li>
            </ul>
          </div>
        </div>

        <div className="loginWrapper">
          <div className="card shadow">
            <Backdrop
              sx={{ color: "#000", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={showLoader}
              className="formLoader"
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <h3>SignUp</h3>
            <span style={{ color: "red" }}>
              {phoneAvailable}
              {passAvailable}
              {confirmPassAvailable}
            </span>
            <form className="mt-4">
              <div className="form-group mb-4 w-100">
                <TextField
                  id="fullName"
                  type="text"
                  name="fullName"
                  label="Name"
                  className="w-100"
                  onChange={onChangeField}
                  value={formFields.fullName}
                />
              </div>
              <div className="form-group mb-4 w-100">
                <TextField
                  id="phone"
                  type="text"
                  name="phone"
                  label="Phone"
                  className="w-100"
                  onChange={onChangeField}
                  value={formFields.phone}
                  onBlur={onCheckWithPhone}
                />
              </div>
              <div className="form-group mb-4 w-100">
                <TextField
                  id="email"
                  type="email"
                  name="email"
                  label="Email"
                  className="w-100"
                  onChange={onChangeField}
                  value={formFields.email}
                />
              </div>
              <div className="form-group mb-4 w-100">
                <div className="position-relative">
                  <TextField
                    id="password"
                    type={showPassword === false ? "password" : "text"}
                    name="password"
                    label="Password"
                    className="w-100"
                    onChange={onChangeField}
                    value={formFields.password}
                    onBlur={onPasswordValidCheck}
                  />
                  <Button
                    className="icon"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword === false ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <VisibilityOutlinedIcon />
                    )}
                  </Button>
                  <span style={{ color: "red" }}>
                    Password should have minimum eight characters, one uppercase
                    letter, and one alphanumeric number
                  </span>
                </div>
              </div>

              <div className="form-group mb-4 w-100">
                <div className="position-relative">
                  <TextField
                    id="conformPassword"
                    type={showPassword1 === false ? "password" : "text"}
                    name="confirmPassword"
                    label="Confirm Password"
                    className="w-100"
                    onChange={onChangeField}
                    value={formFields.confirmPassword}
                  />
                  <Button
                    className="icon"
                    onClick={() => setShowPassword1(!showPassword1)}
                  >
                    {showPassword1 === false ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <VisibilityOutlinedIcon />
                    )}
                  </Button>
                </div>
              </div>

              <div className="form-group mt-5 mb-4 w-100">
                <Button className="btn btn-g btn-lg w-100" onClick={onsignUp}>
                  Sign Up
                </Button>
              </div>

              <p className="text-center">
                Already have an account
                <b>
                  <Link to="/signIn">Sign In</Link>
                </b>
              </p>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUp;
