import axios  from "axios";
import { GENERALURL } from "../constants/constants";

export const createContact = async (contact) => {
    const validCheck = await axios
      .post(`${GENERALURL}/contactRequest`, contact)
      .then((res) => res.data);
    return validCheck;
};

export const createNewsLetter = async (email) => {
  const validCheck = await axios
    .post(`${GENERALURL}/newsLetter`, {email: email})
    .then((res) => res.data);
  return validCheck;
};

export const createAddress = (addressData) => {
  const addres = axios.post(`${GENERALURL}/address`, addressData)
    .then(res => res.data);
    return addres;
};

export const updateAddress = async (id, updatedAddress) => {
  const upres = await axios.put(`${GENERALURL}/address/${id}`, updatedAddress)
    .then(response => response.data)
  return upres;
};

export const deleteAddress = async (id) => {
  const delRes = await axios.delete(`${GENERALURL}/address/${id}`) 
    .then(response => response.data);
  return delRes;
};

export const getAddress = async (id) => {
  const getRes = await axios.get(`${GENERALURL}/address/${id}`) 
    .then(response => response.data);
  return getRes;
};

export const getAddressPincode = pincode => {
  const getRes = axios.get(`https://api.postalpincode.in/pincode/${pincode}`) 
    .then(response => response.data);
  return getRes;
};
