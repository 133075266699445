import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Faq = () => {
  useEffect(() => { window.scrollTo(0, 0); }, []);
  return (
    <section className="listingPage">
      <div className="container-fluid">
        <div className="breadcrumb flex-column">
          <h1 className="text-capitalize">FAQ's</h1>
          <ul className="list list-inline mb-0">
            <li className="list-inline-item">
              <Link to={"/"}>Home</Link>
            </li>
          </ul>
        </div>

        <div className="ps-faqs">
        <div className="m-4 p-2 w-75">
          <div style={{textAlign: 'center'}}>
            <h1>Frequently Asked Questions</h1>
          </div>
          <div className="ps-section__content">
            <div className="table-responsive">
              <div>

                <h3>1. What is dropshipping?</h3>
                <p>
                  Dropshipping is a retail fulfillment method where a store
                  doesn't keep the products it sells in stock. Instead, when you
                  purchase a product, it is shipped directly from our supplier
                  to your doorstep.
                </p>

                <h3>2. How long does shipping take?</h3>
                <p>
                  Shipping times vary depending on the product and your
                  location. Typically, it takes between 7-20 business days for
                  orders to arrive. Please refer to our Shipping Policy for more
                  detailed information.
                </p>

                <h3>3. How can I track my order?</h3>
                <p>
                  Once your order has been shipped, we will send you a tracking
                  number via email. You can use this tracking number on our
                  website or the carrier's website to track your order's
                  progress.
                </p>

                <h3>4. What payment methods do you accept?</h3>
                <p>
                  We accept a variety of payment methods including major credit
                  cards, Debit Cards, Net Banking, UPI and other
                  secure payment options.
                </p>

                <h3>5. Can I change or cancel my order?</h3>
                <p>
                  Orders can be changed or canceled within 24 hours of placing
                  them. Please contact our customer service team at customercare@merciemart.com 
                  as soon as possible if you need to make
                  changes or cancel your order.
                </p>

                <h3>6. What is your return policy?</h3>
                <p>
                  We offer a 7-day return policy for most items. To be eligible
                  for a return, items must be unused and in their original
                  packaging. Please refer to our Return Policy for complete
                  details.
                </p>

                <h3>7. How do I return an item?</h3>
                <p>
                  To return an item, please contact our customer service team at
                  contact@merciemart.com to request a return authorization
                  number (RMA). Follow the instructions provided to ship the
                  item back to us.
                </p>

                <h3>
                  8. What should I do if I receive a damaged or defective item?
                </h3>
                <p>
                  If you receive a damaged or defective item, please contact us
                  at contact@merciemart.com within 7 days of receiving the
                  item. We will arrange for a replacement or refund at no
                  additional cost.
                </p>

                <h3>9. Do you ship internationally?</h3>
                <p>
                  Yes, we offer international shipping to many countries.
                  Shipping fees and delivery times vary depending on the
                  destination. Please refer to our Shipping Policy for more
                  details.
                </p>

                <h3>10. How can I contact customer support?</h3>
                <p>
                  You can reach our customer support team by email at contact@merciemart.com 
                  or by phone at 9032889822. 
                  We are available Monday to Saturday, from 9 AM to 5 PM
                  (IST).
                </p>

                <h3>11. How do I create an account?</h3>
                <p>
                  To create an account, click on the "Sign Up" button at the top
                  right corner of our website and fill out the required
                  information. Creating an account allows you to track your
                  orders and manage your personal information easily.
                </p>

                <h3>12. Do you offer discounts for bulk purchases?</h3>
                <p>
                  Yes, we offer discounts for bulk purchases. Please contact our
                  sales team at contact@merciemart.com for more
                  information on bulk order pricing and discounts.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      </div>
    </section>
  );
};

export default Faq;
