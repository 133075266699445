import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Usersidebar from "../../components/Usersidebar";
import { MyContext } from "../../App";
import { getCouponsByUserId } from "../../api/userApi";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const Coupon = () => {
  const [Coupons, setCoupons] = useState([]);
  const context = useContext(MyContext);
  const getCoupons = async () => {
    if (context.user) {
      const response = await getCouponsByUserId(context.user);
      setCoupons(response);
    }
  };

  useEffect(() => {
    getCoupons();
  }, [context.user]);
  return (
    <>
      {context.windowWidth > 992 && (
        <div className="breadcrumbWrapper mb-4">
          <div className="container-fluid">
            <ul className="breadcrumb breadcrumb2 mb-0">
              <li>
                <Link to={"/"}>Home</Link>
              </li>
              <li>User</li>
              <li>Coupon</li>
            </ul>
          </div>
        </div>
      )}

      <section className="listingPage">
        <div className="container-fluid">
          <div className="listingData">
            <div className="row">
              {context.windowWidth > 992 && (
                <div className="col-md-3 sidebarWrapper">
                  <Usersidebar />
                </div>
              )}

              <div
                className={
                  context.windowWidth > 992 ? "col-md-9 pt-0" : "col-md-12 pt-0"
                }
              >
                <div className="card border-0 shadow p-5">
                  <h3 className="mb-3">My Coupons </h3>

                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontSize: "20px" }} align="center">
                            Name
                          </TableCell>
                          <TableCell sx={{ fontSize: "20px" }} align="center">
                            Phone
                          </TableCell>
                          <TableCell sx={{ fontSize: "20px" }} align="center">
                            Coupon
                          </TableCell>
                          <TableCell sx={{ fontSize: "20px" }} align="center">
                            Total Value
                          </TableCell>
                          <TableCell sx={{ fontSize: "20px" }} align="center">
                            Used
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Coupons.map((row) => (
                          <TableRow
                            key={row.coupon}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ fontSize: "20px" }}
                              align="center"
                            >
                              {row.assignedTo}
                            </TableCell>
                            <TableCell sx={{ fontSize: "20px" }} align="center">
                              {row.assignedPhone}
                            </TableCell>
                            <TableCell sx={{ fontSize: "20px" }} align="center">
                              {row.coupon}
                            </TableCell>
                            <TableCell sx={{ fontSize: "20px" }} align="center">
                              {row.value}
                            </TableCell>
                            <TableCell sx={{ fontSize: "20px" }} align="center">
                              {row.used.toFixed(2)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Coupon;
