import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Listing from './pages/Listing'
import DetailsPage from './pages/Details'
import Cart from './pages/cart'
import Checkout from './pages/checkout'

import NotFound from './pages/NotFound'
import data from './data/data';
import SignIn from './pages/SignIn'
import SignUp from './pages/SignUp'
import Wishlist from './pages/wishlist'
import Faq from './pages/Faq'
import About from './pages/About'
import Privacy from './pages/Privacy/Privacy'
import Tandc from './pages/Tandc/Tandc'
import Contact from './pages/Contact/Contact'
import Account from './pages/User/Account'
import Orders from './pages/User/Orders'
import Address from './pages/User/Address'
import Coupon from './pages/User/Coupon'
import ListingMain from './pages/LIstingMain'

const EmartRoutes = () => {
  return (
    <Routes>
         <Route exact={true} path="/" element={<Home data={data.productData}/>} />
        <Route exact={true} path="/cat/:main/:sub" element={<Listing data={data.productData} />} />
        <Route exact={true} path="/cat/:main/:sub/:low" element={<Listing data={data.productData} single={false} />} />
        <Route exact={true} path="/product/:id" element={<DetailsPage data={data.productData} />} />
        <Route exact={true} path="/cart" element={<Cart />} />
        <Route exact={true} path="/wishlist" element={<Wishlist />} />
        <Route exact={true} path="/signIn" element={<SignIn />} />
        <Route exact={true} path="/signUp" element={<SignUp />} />
        <Route exact={true} path="/checkout" element={<Checkout />} /> 
        <Route exact={true} path="*" element={<NotFound />} />
        <Route exact={true} path="/about" element={<About />} />
        <Route exact={true} path="/faq" element={<Faq />}/>
        <Route exact={true} path="/privacy" element={<Privacy />} />
        <Route exact={true} path="/tandc" element={<Tandc />} />
        <Route exact={true} path="/contact" element={<Contact />} />
        <Route exact={true} path="/myaccount" element={<Account />} />
        <Route exact={true} path="/myorders" element={<Orders />} />
        <Route exact={true} path="/myaddress" element={<Address />} />
        <Route exact={true} path="/mycoupons" element={<Coupon />} />
        <Route exact={true} path="/catmain/:main" element={<ListingMain />} />
      </Routes>
  )
}

export default EmartRoutes