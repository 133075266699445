import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchProducts } from '../api/api';

const initialState = {
  value: 0,
  products: {},
};

export const fetchCJProducts = createAsyncThunk(
  'fetchProducts',
  async ({page, pageSize}, { getState }) => {
    const  categories = getState();
    const response = await fetchProducts(categories.categories.category, page, pageSize);
    return response;
  }
);

export const CJSlice = createSlice({
  name: 'CJProducts',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchCJProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCJProducts.fulfilled, (state, action) => {
        state.status = 'idle';
        state.products = action.payload;
      });
  },
});

export default CJSlice.reducer;
