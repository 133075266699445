import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Usersidebar from "../../components/Usersidebar";
import { MyContext } from "../../App";
import {
  createAddress,
  deleteAddress,
  getAddress,
  getAddressPincode,
} from "../../api/generalApi";
import { Button, TextField } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const Address = () => {
  const [address, setAddress] = useState([]);
  const context = useContext(MyContext);
  const [formFields, setformFields] = useState({
    merci_name: "",
    merci_mobile: "",
    merci_pincode: "",
    merci_address: "",
    merci_locality: "",
    merci_landmark: "",
    merci_city: "",
    merci_state: "",
    merci_alternate_mobile: "",
  });

  const changeInput = (e) => {
    const { name, value } = e.target;

    setformFields(() => ({
      ...formFields,
      [name]: value,
    }));
  };

  const fetchAddress = async () => {
    if (context.user) {
      const res = await getAddress(context.user);
      setAddress(res);
    }
  };

  const getAddressAPI = async () => {
    const res = await getAddressPincode(formFields.merci_pincode);
    setformFields({
      ...formFields,
      merci_locality: res[0].PostOffice[0].Name,
      merci_city: res[0].PostOffice[0].Block,
      merci_state: res[0].PostOffice[0].State,
    });
  };

  const addNewAddress = async () => {
    const res = await createAddress({
      ...formFields,
      merci_id: context.user,
    });
    alert(res.message);
    setformFields({
      merci_name: "",
      merci_mobile: "",
      merci_pincode: "",
      merci_address: "",
      merci_locality: "",
      merci_city: "",
      merci_state: "",
      merci_landmark: "",
      merci_alternate_mobile: "",
    });
    fetchAddress();
  };

  const handleDeleteAddress = async (id) => {
    const res = await deleteAddress(id);
    alert(res.message);
    fetchAddress();
  };

  useEffect(() => {
    fetchAddress();
  }, [context.user]);

  return (
    <>
      {context.windowWidth > 992 && (
        <div className="breadcrumbWrapper mb-4">
          <div className="container-fluid">
            <ul className="breadcrumb breadcrumb2 mb-0">
              <li>
                <Link to={"/"}>Home</Link>
              </li>
              <li>User</li>
              <li>Address</li>
            </ul>
          </div>
        </div>
      )}

      <section className="listingPage">
        <div className="container-fluid">
          <div className="listingData">
            <div className="row">
              {context.windowWidth > 992 && (
                <div className="col-md-3 sidebarWrapper">
                  <Usersidebar />
                </div>
              )}

              <div className={context.windowWidth > 992 ? 'col-md-9 pt-0' : 'col-md-12 pt-0'}>
                <div className="card border-0 shadow p-5">
                  <h3 className="mb-3">My Address </h3>

                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontSize: "20px" }} align="center">
                            Name
                          </TableCell>
                          <TableCell sx={{ fontSize: "20px" }} align="center">
                            Phone
                          </TableCell>
                          <TableCell sx={{ fontSize: "20px" }} align="center">
                            Address
                          </TableCell>
                          <TableCell sx={{ fontSize: "20px" }} align="center">
                            Landmark
                          </TableCell>
                          <TableCell sx={{ fontSize: "20px" }} align="center">
                            Alternate Phone
                          </TableCell>
                          <TableCell sx={{ fontSize: "20px" }} align="center">
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {address.map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ fontSize: "20px" }}
                              align="center"
                            >
                              {row.merci_name}
                            </TableCell>
                            <TableCell sx={{ fontSize: "20px" }} align="center">
                              {row.merci_mobile}
                            </TableCell>
                            <TableCell sx={{ fontSize: "20px" }} align="center">
                              {row.merci_address}, {row.merci_locality},{" "}
                              {row.merci_city}, {row.merci_state}
                            </TableCell>
                            <TableCell sx={{ fontSize: "20px" }} align="center">
                              {row.merci_landmark || "NA"}
                            </TableCell>
                            <TableCell sx={{ fontSize: "20px" }} align="center">
                              {row.merci_alternate_phone || "NA"}
                            </TableCell>
                            <TableCell sx={{ fontSize: "20px" }} align="center">
                              <Button
                                className="btn-g mt-2"
                                onClick={() => handleDeleteAddress(row._id)}
                              >
                                Delete
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <div className="col-md-9">
                    <div className="form w-100 mt-4">
                      <h3>Add Shipping Address</h3>
                      <div className="form-group mb-3 mt-4">
                        <TextField
                          id="outlined-basic"
                          label="Enter Full Name"
                          variant="outlined"
                          className="w-100"
                          value={formFields.merci_name}
                          onChange={changeInput}
                          name="merci_name"
                          InputProps={{
                            style: { fontSize: "20px" },
                          }}
                          InputLabelProps={{
                            style: { fontSize: "20px" },
                          }}
                        />
                      </div>
                      <div className="form-group mb-3 mt-4">
                        <TextField
                          id="outlined-basic"
                          label="Phone Number"
                          variant="outlined"
                          className="w-100"
                          value={formFields.merci_mobile}
                          onChange={changeInput}
                          name="merci_mobile"
                          InputProps={{
                            style: { fontSize: "20px" },
                          }}
                          InputLabelProps={{
                            style: { fontSize: "20px" },
                          }}
                        />
                      </div>

                      <div className="form-group mb-3">
                        <TextField
                          id="outlined-basic"
                          label="Enter Pincode"
                          variant="outlined"
                          className="w-100"
                          value={formFields.merci_pincode}
                          onChange={changeInput}
                          onBlur={getAddressAPI}
                          name="merci_pincode"
                          InputProps={{
                            style: { fontSize: "20px" },
                          }}
                          InputLabelProps={{
                            style: { fontSize: "20px" },
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <TextField
                          id="outlined-basic"
                          label="Enter Full Address"
                          variant="outlined"
                          className="w-100"
                          multiline
                          rows={4}
                          value={formFields.merci_address}
                          onChange={changeInput}
                          name="merci_address"
                          InputProps={{
                            style: { fontSize: "20px" },
                          }}
                          InputLabelProps={{
                            style: { fontSize: "20px" },
                          }}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <TextField
                          id="outlined-basic"
                          label="Locality"
                          variant="outlined"
                          className="w-100"
                          value={formFields.merci_locality}
                          onChange={changeInput}
                          name="merci_locality"
                          InputProps={{
                            style: { fontSize: "20px" },
                          }}
                          InputLabelProps={{
                            style: { fontSize: "20px" },
                          }}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <TextField
                          id="outlined-basic"
                          label="City"
                          variant="outlined"
                          className="w-100"
                          value={formFields.merci_city}
                          onChange={changeInput}
                          name="merci_city"
                          InputProps={{
                            style: { fontSize: "20px" },
                          }}
                          InputLabelProps={{
                            style: { fontSize: "20px" },
                          }}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <TextField
                          id="outlined-basic"
                          label="State"
                          variant="outlined"
                          className="w-100"
                          value={formFields.merci_state}
                          onChange={changeInput}
                          name="merci_state"
                          InputProps={{
                            style: { fontSize: "20px" },
                          }}
                          InputLabelProps={{
                            style: { fontSize: "20px" },
                          }}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <TextField
                          id="outlined-basic"
                          label="Near Landmark"
                          variant="outlined"
                          className="w-100"
                          value={formFields.merci_landmark}
                          onChange={changeInput}
                          name="merci_landmark"
                          InputProps={{
                            style: { fontSize: "20px" },
                          }}
                          InputLabelProps={{
                            style: { fontSize: "20px" },
                          }}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <TextField
                          id="outlined-basic"
                          label="Alternate Phone Number."
                          variant="outlined"
                          className="w-100"
                          value={formFields.merci_alternate_mobile}
                          onChange={changeInput}
                          name="merci_alternate_mobile"
                          InputProps={{
                            style: { fontSize: "20px" },
                          }}
                          InputLabelProps={{
                            style: { fontSize: "20px" },
                          }}
                        />
                      </div>
                      <Button className="btn-g btn-lg" onClick={addNewAddress}>
                        Add New Address
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Address;
