import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import './Contact.css'

const Contact = () => {
  useEffect(() => { window.scrollTo(0, 0); }, []);
  return (
    <section className="listingPage">
      <div className="container-fluid">
        <div className="breadcrumb flex-column">
          <h1 className="text-capitalize">Contact Us</h1>
          <ul className="list list-inline mb-0">
            <li className="list-inline-item">
              <Link to={"/"}>Home</Link>
            </li>
          </ul>
        </div>

        <div class="container">
    <div class="content">
      <div class="left-side">
        <div class="address details">
          <i class="fas fa-map-marker-alt"></i>
          <div class="topic">Address</div>
          <div class="text-one">Road No 2, Omkarnagar</div>
          <div class="text-two">Chintalkunta, Hyderabad,</div>
          <div class="text-two">Telangana -  500074</div>
        </div>
        <div class="phone details">
          <i class="fas fa-phone-alt"></i>
          <div class="topic">Phone</div>
          <div class="text-one">+91 8143272975</div>
          <div class="text-two">+91 9032889822</div>
        </div>
        <div class="email details">
          <i class="fas fa-envelope"></i>
          <div class="topic">Email</div>
          <div class="text-one">customercare@merciemart.com</div>
          <div class="text-two">contact@merciemart.com</div>
        </div>
      </div>
      <div class="right-side">
        <div class="topic-text">Send us a message</div>
        <p>If you have any work from me or any types of quries related to my tutorial, you can send me message from here. It's my pleasure to help you.</p>
      <form action="#">
        <div class="input-box">
          <input type="text" placeholder="Enter your name"/>
        </div>
        <div class="input-box">
          <input type="text" placeholder="Enter your email"/>
        </div>
        <div class="input-box message-box">
          <textarea>Enter Message</textarea> 
        </div>
        <div class="button">
          <input type="button" value="Send Now" />
        </div>
      </form>
    </div>
    </div>
  </div>

      </div>
    </section>
  );
};

export default Contact;
