import React, { useRef, useState, useContext } from "react";
import Slider from "react-slick";
import "./style.css";
import { Link } from "react-router-dom";
import Computer from "../../assets/images/computer.png";
import Womens from "../../assets/images/womens.png";
import Mens from "../../assets/images/mens.png";
import Toys from "../../assets/images/toys.png";
import Bags from "../../assets/images/bags.png";
import Beauty from "../../assets/images/beauty.png";
import Home from "../../assets/images/home.png";
import Jewelry from "../../assets/images/jewelry.png";
import Radio from "../../assets/images/radio.png";

import { MyContext } from "../../App";
const CatSlider = (props) => {
  const [allData, setAllData] = useState(props.data);
  const [totalLength, setTotalLength] = useState([]);
  const context = useContext(MyContext);

  const [itemBg, setItemBg] = useState([
    "#fffceb",
    "#ecffec",
    "#feefea",
    "#fff3eb",
    "#fff3ff",
    "#f2fce4",
    "#feefea",
    "#fffceb",
    "#feefea",
    "#ecffec",
    "#feefea",
    "#fff3eb",
    "#fff3ff",
    "#f2fce4",
    "#feefea",
    "#fffceb",
    "#feefea",
    "#ecffec",
  ]);

  const slider = useRef();

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 10,
    slidesToScroll: 1,
    fade: false,
    arrows: context.windowWidth > 992 ? true : false,
    autoplay: context.windowWidth > 992 ? 2000 : false,
    centerMode: context.windowWidth > 992 ? true : false,
  };

 
  const menuItems = [
    {
      title: "Computer",
      link: 'Computer_&_Office',
      icon: Computer,
    },
    {
      title: "Electronics",
      link: 'Consumer Electronics',
      icon: Radio,
    },
    {
      title: "Women's Clothing",
      link: "Women's Clothing",
      icon: Womens,
    },
    {
      title: "Men's Clothing",
      link: "Men's Clothing",
      icon: Mens,
    },
    {
      title: "Kids & Toys",
      link: 'Toys, Kids & Babies',
      icon: Toys,
    },
    {
      title: "Home & Garden",
      link: "Home, Garden & Furniture",
      icon: Home,
    },
    {
      title: "Shoes & Bags",
      link: 'Bags & Shoes',
      icon: Bags,
    },
    {
      title: "Jewelery",
      link: "Jewelry & Watches",
      icon: Jewelry,
    },
    {
      title: "Health & Beauty",
      link: "Health, Beauty & Hair",
      icon: Beauty,
    },
  ];


  return (
    <>
      <div className="catSliderSection">
        <div className="container-fluid" ref={slider}>
          <h2 className="hd">Featured Categories</h2>
          <Slider
            {...settings}
            className="cat_slider_Main"
            id="cat_slider_Main"
          >
            {menuItems.map((menu, index) => {
              return (
                <div className="item" key={index}>
                  <Link to={`/catmain/${menu.link}`}>
                    <div className="info" style={{ background: itemBg[index] }}>
                      <img src={menu.icon} alt="icon" width="100" />
                      <h5 className="text-capitalize mt-3">{menu.title}</h5>
                      {/* <p>{totalLength[index]} items</p> */}
                    </div>
                  </Link>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default CatSlider;
