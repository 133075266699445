import Cookies from "js-cookie";
import { deleteFromCart, updateCart } from "../features/cartSlice";
import { getDiscountPercentage } from "./Percentages";

export const handleCartItems = (product, percentage, dispatch) => {
  const mrpVal = getDiscountPercentage(
    percentage,
    product.merci_mrp,
    parseInt(product.merci_merci_discount)
  );

  const updatedProduct = {
    ...product,
    merci_count: 1,
    merci_percentage: mrpVal.reduced,
    merci_mrp: mrpVal.finalValue,
  };

  let cartItems = Cookies.get("emartCart");
  if (!cartItems) {
    cartItems = [updatedProduct];
  } else {
    cartItems = JSON.parse(cartItems);
    cartItems.push(updatedProduct);
  }

  Cookies.set("emartCart", JSON.stringify(cartItems), { expires: 30 });
  cartItems.map((cart) => dispatch(updateCart(cart)));
  alert("Item Added to Cart");
};

export const generateUniqueId = () => {
  const timestamp = new Date().getTime();
  const random = Math.floor(Math.random() * 1000);
  const uniqueId = `ORID${timestamp}${random}`;
  return uniqueId;
};

export const deleteCartItem = (productId, dispatch) => {
  let cartItems = Cookies.get("emartCart");
  if (!cartItems) {
    alert("No items in the cartItem to delete.");
    return;
  }
  cartItems = JSON.parse(cartItems);
  const updatedWishlist = cartItems.filter((item) => item.id !== productId);
  Cookies.set("emartCart", JSON.stringify(updatedWishlist), { expires: 30 });
  //dispatch(updateCart(updatedWishlist));
  dispatch(deleteFromCart(productId));
  alert("Item Removed from Cart");
};

export const deleteAllCartItem = (dispatch) => {
  Cookies.set("emartCart", JSON.stringify([]), { expires: 30 });
  dispatch(updateCart([]));
};
