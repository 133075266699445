export const getTotalandDeductions = (products) => {
  let totalPrice = 0;
  let totalDeductions = 0;
  let orginalPrice = 0;

  products.forEach((product) => {
    const deduction =
      (product.merci_percentage / 100) *
      product.merci_mrp *
      product.merci_count;
    const orginal = product.merci_mrp * product.merci_count;
    const finalPrice = orginal - deduction;

    orginalPrice += orginal;
    totalPrice += finalPrice;
    totalDeductions += deduction;
  });

  return { totalPrice, totalDeductions, orginalPrice };
};


export const applyDiscount = (discountValue, coupons) => {
 
  const merciId = '666db606ff917cd57e8c285b';
  let usedCoupons = [];

  for (let coupon of coupons) {
    let availableValue = coupon.value - coupon.used;
    if (availableValue > 0) {
      if (discountValue <= availableValue) {
        usedCoupons.push({
          merchantId: coupon.merchantId,
          coupon: coupon.coupon,
          valueUsed: parseFloat(discountValue.toFixed(2))
        });
        discountValue = 0; // All discount value is used
        break;
      } else {
        usedCoupons.push({
          merchantId: coupon.merchantId,
          coupon: coupon.coupon,
          valueUsed: parseFloat(availableValue.toFixed(2))
        });
        discountValue -= availableValue;
      }
    }
  }

  if (discountValue > 0) {
    usedCoupons.push({
      merchantId: merciId,
      coupon: 'defaultMerciCoupon',
      valueUsed: parseFloat(discountValue.toFixed(2))
    });
  }

  return usedCoupons;
};