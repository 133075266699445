import React, { useEffect, useState } from "react";
import "./style.css";

import { Link } from "react-router-dom";
import Rating from "@mui/material/Rating";
import { rupee } from "../../../constants/priceFormats";
import { getDiscountPercentage } from "../../../utils/Percentages";
import { getPercentageList } from "../../../api/PercentageApi";

const TopProducts = (props) => {
  const [percentage, setPercentage] = useState(10);

  const getPercentages = async () => {
    const percentValues = await getPercentageList("cj");
    setPercentage(percentValues.percentageList[0]);
  };
  useEffect(() => {
    getPercentages();
  }, [props.prods]);
  return (
    <>
      <div className="topSelling_box">
        <h3>{props.title}</h3>
        {props.prods.length > 0 &&
          props.prods.map((prod, index) => {
            return (
            <div className="items d-flex align-items-center" key={index}>
              <div className="img">
                <Link to={`/product/${prod._id}`}>
                  <img
                    src={JSON.parse(prod.merci_prod_img.replace(/'/g, '"'))[0]}
                    className="w-100"
                    alt="img"
                  />
                </Link>
              </div>

              <div className="info px-3">
                <Link to={`/product/${prod._id}`}>
                  <h4>{prod.merci_prod_name}</h4>
                </Link>
                <Rating
                  name="half-rating-read"
                  defaultValue={3.5}
                  precision={0.5}
                  readOnly
                />
                <div className="d-flex align-items-center">
                  <span className="price text-g font-weight-bold">
                    {rupee.format(
                      getDiscountPercentage(
                        percentage,
                        prod.merci_mrp,
                        prod.merci_merci_discount
                      ).finalValue
                    )}
                  </span>
                  <span className="oldPrice">
                    {rupee.format(
                      getDiscountPercentage(
                        percentage,
                        prod.merci_mrp,
                        prod.merci_merci_discount
                      ).increasedValue
                    )}
                  </span>
                </div>
              </div>
            </div>
          )})}
      </div>
    </>
  );
};

export default TopProducts;
