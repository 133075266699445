import React, { useContext, useRef, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Rating from "@mui/material/Rating";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import Slider from "react-slick";
import { Button } from "@mui/material";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import axios from "axios";
import { MyContext } from "../../App";
import { fetchProductById } from "../../api/api";
import { getPercentageList } from "../../api/PercentageApi";
import { rupee } from "../../constants/priceFormats";
import { getDiscountPercentage } from "../../utils/Percentages";
import { handleWishlistItems } from "../../utils/Wishlist";
import { useDispatch, useSelector } from "react-redux";
import { handleCartItems } from "../../utils/CartItems";

const DetailsPage = (props) => {
 

  const [bigImageSize, setBigImageSize] = useState([1500, 1500]);
  const [smlImageSize, setSmlImageSize] = useState([150, 150]);

  const mainCat = sessionStorage.getItem("mainCat") || "";
  const subCat = sessionStorage.getItem("subCat") || "";
  const lowCat = sessionStorage.getItem("lowCat") || "";

  const [activeStyle, setActiveStyle] = useState('');
  const [activeSized, setActiveSized] = useState(null);
  const [subproducts, setSubProducts] = useState([]);
  const [percentage, setPercentage] = useState(10);
  const [title, setTitle] = useState('')
  const [mrp, setMrp] = useState('')
  const [img, setImg] = useState('')
  const [secondVar, setSecondVar] = useState([])
  const dispatch = useDispatch();
  const wishlist = useSelector(state => state.wishlist.wishlist);
  const cartItems = useSelector(state => state.cart.cart);


  const { id } = useParams();

  const getProduct = async () => {
    const res = await fetchProductById(id);
    setCurrentProduct(res.productslist);
    const subProductsArr = JSON.parse(
        res.productslist.merci_subprod
        .replaceAll("'s", 's')
        .replace(/'/g, '"')
        .replace(/":\s*"(.*?)"\s*"/g, '": "$1"')
    );

    setSubProducts(subProductsArr);


    const firstStyle = subProductsArr[0].merci_sub_variant.split('-')[0];
    setTitle(subProductsArr[0].merci_sub_name);
    setMrp(subProductsArr[0].merci_sub_mrp);
    setImg(subProductsArr[0].merci_sub_image);
    setActiveStyle(firstStyle);
    // subproducts.filter((prod) => prod.merci_sub_variant.startsWith(activeStyle));
      
  };

  const getPercentages = async () => {
    const percentValues = await getPercentageList("cj");
    setPercentage(percentValues.percentageList[0]);
  };



 
  const [activeTabs, setActiveTabs] = useState(0);

  const [currentProduct, setCurrentProduct] = useState({});
  const [isAdded, setIsadded] = useState(false);

  const context = useContext(MyContext);



 
  const [rating, setRating] = useState(0.0);
  const [reviewsArr, setReviewsArr] = useState([]);
  const [isAlreadyAddedInCart, setisAlreadyAddedInCart] = useState(false);
  const [reviewFields, setReviewFields] = useState({
    review: "",
    userName: "",
    rating: 0.0,
    productId: 0,
    date: "",
  });

  const zoomSliderBig = useRef();
  const zoomSlider = useRef();

  var settings2 = {
    dots: false,
    infinite: false,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
    arrows: false,
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    fade: false,
    arrows: context.windowWidth > 992 ? true : false,
  };


  const goto = (index) => {
    zoomSlider.current.slickGoTo(index);
    zoomSliderBig.current.slickGoTo(index);
  };


  useEffect(() => {
    window.scrollTo(0, 0);

    getProduct();
    getPercentages();

  }, [cartItems]);


  const handleStyleClick = (style) => {
    setActiveStyle(style);
    const selectedArr = subproducts.filter(el => el.merci_sub_variant.split('-')[0] === style)
    setTitle(selectedArr[0].merci_sub_name);
    setMrp(selectedArr[0].merci_sub_mrp)
    setImg(selectedArr[0].merci_sub_image)
    setActiveSized(0); // Reset size when style changes
  };

  const handleSizeClick = (sty, index) => {
    setActiveSized(index);
    const selectedArr = subproducts.filter(el => el.merci_sub_variant === sty)
    setTitle(selectedArr[0].merci_sub_name);
    setMrp(selectedArr[0].merci_sub_mrp);
    setImg(selectedArr[0].merci_sub_image);
  };

  const styles = [...new Set(subproducts.map((prod) => prod.merci_sub_variant.split('-')[0]))];
  const sizes = subproducts.filter((prod) => prod.merci_sub_variant.startsWith(activeStyle));


  const changeInput = (name, value) => {
    if (name === "rating") {
      setRating(value);
    }
    setReviewFields(() => ({
      ...reviewFields,
      [name]: value,
      productId: id,
      date: new Date().toLocaleString(),
    }));
  };

  const reviews_Arr = [];

  const submitReview = async (e) => {
    e.preventDefault();

    try {
      await axios
        .post("http://localhost:5000/productReviews", reviewFields)
        .then((response) => {
          reviews_Arr.push(response.data);
          setReviewFields(() => ({
            review: "",
            userName: "",
            rating: 0.0,
            productId: 0,
            date: "",
          }));
        });
    } catch (error) {
      console.log(error.message);
    }

    showReviews();
  };

  var reviews_Arr2 = [];
  const showReviews = async () => {
    try {
      await axios
        .get("http://localhost:5000/productReviews")
        .then((response) => {
          if (response.data.length !== 0) {
            response.data.map((item) => {
              if (parseInt(item.productId) === parseInt(id)) {
                reviews_Arr2.push(item);
              }
            });
          }
        });
    } catch (error) {
      console.log(error.message);
    }

    if (reviews_Arr2.length !== 0) {
      setReviewsArr(reviews_Arr2);
    }
  };

  const addToCart = () => {
    const transformedProduct = {
      id: currentProduct._id,
      merci_spu_id: currentProduct.merci_spu_id,
      merci_prod_name: title,
      merci_prod_img: img,
      merci_mrp: mrp,
      merci_merci_discount: currentProduct.merci_merci_discount
    };
    handleCartItems(transformedProduct, percentage, dispatch)
  };

  const addToWishlist = () => {
    const transformedProduct = {
      id: currentProduct._id,
      merci_spu_id: currentProduct.merci_spu_id,
      merci_prod_name: title,
      merci_prod_img: img,
      merci_mrp: mrp,
      merci_merci_discount: currentProduct.merci_merci_discount
    };
    handleWishlistItems(transformedProduct, percentage, dispatch)
  }

  return (
    <>
      {context.windowWidth < 992 && (
        <Button
          className={`btn-g btn-lg w-100 filterBtn {isAlreadyAddedInCart===true && 'no-click'}`}
          onClick={() => addToCart(currentProduct)}
        >
          <ShoppingCartOutlinedIcon />
          {isAdded === true || isAlreadyAddedInCart === true
            ? "Added"
            : "Add To Cart"}
        </Button>
      )}
      <section className="detailsPage mb-5">
        {context.windowWidth > 992 && (
          <div className="breadcrumbWrapper mb-4">
            <div className="container-fluid">
              <ul className="breadcrumb breadcrumb2 mb-0">
                <li>
                  <Link>Home</Link>{" "}
                </li>
                <li>
                  <Link
                    to={`/cat/${mainCat.replaceAll("_", " ")}`}
                    className="text-capitalize"
                  >
                    {mainCat}
                  </Link>{" "}
                </li>

                <li>
                  <Link
                    to={`/cat/${mainCat.replaceAll(
                      "_",
                      " "
                    )}/${subCat.replaceAll("_", " ")}`}
                    className="text-capitalize"
                  >
                    {subCat}
                  </Link>{" "}
                </li>
                <li>{currentProduct.productName}</li>
              </ul>
            </div>
          </div>
        )}

        <div className="container detailsContainer pt-3 pb-3">
          <div className="row">
            {/* productZoom code start here */}
            <div className="col-md-5">
              <div className="productZoom">
                <Slider
                  {...settings2}
                  className="zoomSliderBig"
                  ref={zoomSliderBig}
                >
                  {currentProduct.merci_prod_img !== undefined &&
                    JSON.parse(
                      currentProduct.merci_prod_img.replace(/'/g, '"')
                    ).map((imgUrl, index) => {
                      return (
                        <div className="item" key={index}>
                          <InnerImageZoom
                            zoomType="hover"
                            zoomScale={1}
                            src={`${imgUrl}?im=Resize=(${bigImageSize[0]},${bigImageSize[1]})`}
                          />
                        </div>
                      );
                    })}
                </Slider>
              </div>

              <Slider {...settings} className="zoomSlider" ref={zoomSlider}>
                {currentProduct.merci_prod_img !== undefined &&
                  JSON.parse(
                    currentProduct.merci_prod_img.replace(/'/g, '"')
                  ).map((imgUrl, index) => {
                    return (
                      <div className="item">
                        <img
                          src={`${imgUrl}?im=Resize=(${smlImageSize[0]},${smlImageSize[1]})`}
                          className="w-100"
                          onClick={() => goto(index)}
                          alt="ProductImage"
                        />
                      </div>
                    );
                  })}
              </Slider>
            </div>
            {/* productZoom code ends here */}

            {/* product info code start here */}
            <div className="col-md-7 productInfo">
              <h1>{title}</h1>
              <div className="d-flex align-items-center mb-4 mt-3">
                <Rating
                  name="half-rating-read"
                  value={parseFloat(4.0)}
                  precision={0.5}
                  readOnly
                />
                <span className="text-light ml-2">(102 reviews)</span>
              </div>

              <div className="priceSec d-flex align-items-center mb-3">
                <span className="text-g priceLarge">
                  { rupee.format(getDiscountPercentage(percentage, mrp, currentProduct.merci_merci_discount).finalValue)}
                </span>
                <div className="ml-3 d-flex flex-column">
                  <span className="text-org">
                    {getDiscountPercentage(percentage, mrp, currentProduct.merci_merci_discount).reduced}% Off
                  </span>
                  <span className="text-light oldPrice">
                    Rs {rupee.format(getDiscountPercentage(percentage, mrp, currentProduct.merci_merci_discount).increasedValue)}
                  </span>
                </div>
              </div>
              
                  <div className="productSize d-flex align-items-center">
                    <span>Size / Weight:</span>
                    <ul className="list list-inline mb-0 pl-4">
                      
                          <li className="list-inline-item">
                           {currentProduct.merci_weight}
                          </li>
                    </ul>
                  </div>
                  <div className="productSize d-flex align-items-center">
                    <span>Material:</span>
                    <ul className="list list-inline mb-0 pl-4">
                      
                          <li className="list-inline-item">
                           {currentProduct.merci_material}
                          </li>
                    </ul>
                  </div>

                  <div className="productSize d-flex align-items-center">
        {Object.keys(currentProduct).length !== 0 && styles && (
          <>
            <span>{currentProduct.merci_variant_key.split('-')[0]}</span>
            <ul className="list list-inline mb-0 pl-4">
              {styles.map((style, index) => (
                <li className="list-inline-item" key={index} style={{marginTop: '10px'}}>
                  <a
                    className={`style-button ${activeStyle === style ? 'active' : ''}`}
                    onClick={() => handleStyleClick(style)}
                  >
                    {style}
                  </a>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      { Object.keys(currentProduct).length !== 0 &&
      <div className="productSize d-flex align-items-center">
        { currentProduct.merci_variant_key.split('-').length > 1 && (
          <>
            <span>{currentProduct.merci_variant_key.split('-')[1]}</span>
            <ul className="list list-inline mb-0 pl-4">
              {sizes.map((SIZE, index) => (
                <li className="list-inline-item" key={index} style={{marginTop: '10px'}}>
                  <a
                    className={`tag ${activeSized === index ? 'active' : ''}`}
                    onClick={() => handleSizeClick(SIZE.merci_sub_variant, index)}
                  >
                    {SIZE.merci_sub_variant.split('-')[1]}
                  </a>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      }
              {/* {currentProduct.merci_variant_key !== undefined &&
                currentProduct.merci_variant_key.length !== 0 &&
                currentProduct.merci_variant_key
                  .split("-")
                  .map((variant, ind) => {
                    return (
                      <div className="productSize d-flex align-items-center">
                        <span>{variant}</span>
                        <ul className="list list-inline mb-0 pl-4">
                            {JSON.parse(currentProduct.merci_subprod.replace(/'/g, '"').replace(/":\s*"(.*?)"\s*"/g, '": "$1"')).map(
                            (SIZE, index) => {
                              return (
                                <li className="list-inline-item">
                                  <a
                                    className={`tag ${
                                      activeSize === index ? "active" : ""
                                    }`}
                                    onClick={() => isActive(index)}
                                  >
                                    {SIZE.merci_sub_variant.split('-')[ind]}
                                  </a>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    );
                  })} */}
              {/* <div className='productSize d-flex align-items-center'>
                                    <span>SIZE:</span>
                                    <ul className='list list-inline mb-0 pl-4'>
                                        {
                                            currentProduct.SIZE.map((SIZE, index) => {
                                                return (
                                                    <li className='list-inline-item'><a className={`tag ${activeSize === index ? 'active' : ''}`} onClick={() => isActive(index)}>{SIZE}</a></li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div> */}

              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center">
                  {context.windowWidth > 992 && (
                    <Button
                      className={`btn-g btn-lg addtocartbtn ${
                        cartItems.some(cart => cart.merci_prod_name === title)  && "no-click"
                      }`}
                      onClick={() => addToCart()}
                    >
                      <ShoppingCartOutlinedIcon />
                      { cartItems.some(cart => cart.merci_prod_name === title)
                        ? "Added"
                        : "Add To Cart"}
                    </Button>
                  )}
                  <Button className=" btn-lg addtocartbtn  ml-3  wishlist btn-border" onClick={() => addToWishlist()}> 
                    <FavoriteBorderOutlinedIcon />  Add to Wishlist
                  </Button>
                </div>
              </div>
            </div>
            {/* product info code ends here */}
          </div>

          <div className="card mt-5 p-5 detailsPageTabs">
            <div className="customTabs">
              <ul className="list list-inline">
                <li className="list-inline-item">
                  <Button
                    className={`${activeTabs === 0 && "active"}`}
                    onClick={() => {
                      setActiveTabs(0);
                    }}
                  >
                    Description
                  </Button>
                </li>
                <li className="list-inline-item">
                  <Button
                    className={`${activeTabs === 2 && "active"}`}
                    onClick={() => {
                      setActiveTabs(1);
                      showReviews();
                    }}
                  >
                    Reviews
                  </Button>
                </li>
              </ul>

              <br />

              {activeTabs === 0 && (
                <div
                  className="tabContent"
                  dangerouslySetInnerHTML={{
                    __html: currentProduct.merci_description,
                  }}
                ></div>
              )}

              {activeTabs === 1 && (
                <div className="tabContent">
                  <div className="row">
                    <div className="col-md-8">
                      <h3>Customer questions & answers</h3>
                      <br />

                      {reviewsArr.length !== 0 &&
                        reviewsArr !== undefined &&
                        reviewsArr.map((item, index) => {
                          return (
                            <div
                              className="card p-4 reviewsCard flex-row"
                              key={index}
                            >
                              <div className="image">
                                <div className="rounded-circle">
                                  <img src="https://wp.alithemes.com/html/nest/demo/assets/imgs/blog/author-2.png" />
                                </div>
                                <span className="text-g d-block text-center font-weight-bold">
                                  {item.userName}
                                </span>
                              </div>

                              <div className="info pl-5">
                                <div className="d-flex align-items-center w-100">
                                  <h5 className="text-light">{item.date}</h5>
                                  <div className="ml-auto">
                                    <Rating
                                      name="half-rating-read"
                                      value={parseFloat(item.rating)}
                                      precision={0.5}
                                      readOnly
                                    />
                                  </div>
                                </div>

                                <p>{item.review} </p>
                              </div>
                            </div>
                          );
                        })}

                      <br className="res-hide" />

                      <br className="res-hide" />

                      <form className="reviewForm" onSubmit={submitReview}>
                        <h4>Add a review</h4> <br />
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            placeholder="Write a Review"
                            name="review"
                            value={reviewFields.review}
                            onChange={(e) =>
                              changeInput(e.target.name, e.target.value)
                            }
                          ></textarea>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                type="text"
                                value={reviewFields.userName}
                                className="form-control"
                                placeholder="Name"
                                name="userName"
                                onChange={(e) =>
                                  changeInput(e.target.name, e.target.value)
                                }
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <Rating
                                name="rating"
                                value={rating}
                                precision={0.5}
                                onChange={(e) =>
                                  changeInput(e.target.name, e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className="form-group">
                          <Button type="submit" className="btn-g btn-lg">
                            Submit Review
                          </Button>
                        </div>
                      </form>
                    </div>

                    <div className="col-md-4 pl-5 reviewBox">
                      <h4>Customer reviews</h4>

                      <div className="d-flex align-items-center mt-2">
                        <Rating
                          name="half-rating-read"
                          defaultValue={4.5}
                          precision={0.5}
                          readOnly
                        />
                        <strong className="ml-3">4.8 out of 5</strong>
                      </div>

                      <br />

                      <div className="progressBarBox d-flex align-items-center">
                        <span className="mr-3">5 star</span>
                        <div
                          class="progress"
                          style={{ width: "85%", height: "20px" }}
                        >
                          <div
                            class="progress-bar bg-success"
                            style={{ width: "75%", height: "20px" }}
                          >
                            75%
                          </div>
                        </div>
                      </div>

                      <div className="progressBarBox d-flex align-items-center">
                        <span className="mr-3">4 star</span>
                        <div
                          class="progress"
                          style={{ width: "85%", height: "20px" }}
                        >
                          <div
                            class="progress-bar bg-success"
                            style={{ width: "50%", height: "20px" }}
                          >
                            50%
                          </div>
                        </div>
                      </div>

                      <div className="progressBarBox d-flex align-items-center">
                        <span className="mr-3">3 star</span>
                        <div
                          class="progress"
                          style={{ width: "85%", height: "20px" }}
                        >
                          <div
                            class="progress-bar bg-success"
                            style={{ width: "55%", height: "20px" }}
                          >
                            55%
                          </div>
                        </div>
                      </div>

                      <div className="progressBarBox d-flex align-items-center">
                        <span className="mr-3">2 star</span>
                        <div
                          class="progress"
                          style={{ width: "85%", height: "20px" }}
                        >
                          <div
                            class="progress-bar bg-success"
                            style={{ width: "35%", height: "20px" }}
                          >
                            35%
                          </div>
                        </div>
                      </div>

                      <div className="progressBarBox d-flex align-items-center">
                        <span className="mr-3">1 star</span>
                        <div
                          class="progress"
                          style={{ width: "85%", height: "20px" }}
                        >
                          <div
                            class="progress-bar bg-success"
                            style={{ width: "25%", height: "20px" }}
                          >
                            25%
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <br />

          {/* <div className="relatedProducts homeProductsRow2  pt-5 pb-4">
            <h2 class="hd mb-0 mt-0">Related products</h2>
            <br className="res-hide" />
            <Slider {...related} className="prodSlider">
              {relatedProducts.length !== 0 &&
                relatedProducts.map((product, index) => {
                  return (
                    <div className="item" key={index}>
                      <Product tag={product.type} item={product} />
                    </div>
                  );
                })}
            </Slider>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default DetailsPage;
