export const getDiscountPercentage = (percentage, value, discount) => {

  
  let val = `${value}`.split(" -- ").length > 1 ? parseFloat( value.split(" -- ")[1]) * 85 : parseFloat(value) * 85

  
    const discountPercentage = discount / 100;
    const increasedValue = parseFloat((val + val * discountPercentage).toFixed(2));

    let finalValue;
    let reduced;

    if (discount <= percentage.sublowMax) {
      finalValue = (increasedValue - increasedValue * (percentage.sublowPercent/100) ).toFixed(2); // 10% reduction
      reduced = percentage.sublowPercent;
    }  else if (discount >= percentage.lowMin && discount <= percentage.lowMax) {
      finalValue = (increasedValue - increasedValue * (percentage.lowPercent/100)).toFixed(2); // 15% reduction
      reduced = percentage.lowPercent;
    } else if (discount >= percentage.midMin && discount <= percentage.midMax) {
      finalValue = (increasedValue - increasedValue * (percentage.midPercent/100)).toFixed(2); // 15% reduction
      reduced = percentage.midPercent;
    } else if (discount >= percentage.highMin && discount <= percentage.highMax) {
      finalValue = (increasedValue - increasedValue * (percentage.highPercent/100)).toFixed(2); // 20% reduction
      reduced = percentage.highPercent;
    } else {
      finalValue = increasedValue;
    }
    
    return { finalValue, reduced, increasedValue };
  };


  export const getPercentage = (val, discount) => {
    const discountPercentage = discount / 100;
    const increasedValue = val + val * discountPercentage;
    return (increasedValue).toFixed(2);
  };