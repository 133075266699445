import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Product from "../../components/product";
import { Box, Button, Pagination, PaginationItem } from "@mui/material";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { useSelector, useDispatch } from "react-redux";
import { MyContext } from "../../App";
import { updateCategory } from "../../features/categorySlice";
import { fetchCJProducts } from "../../features/productsSlice";
import Loader from "../../assets/images/loading.gif";
import { styled } from '@mui/system';

const Listing = () => {
  const [isOpenDropDown, setisOpenDropDown] = useState(false);
  const [isOpenDropDown2, setisOpenDropDown2] = useState(false);
  const [showPerPage, setHhowPerPage] = useState(3);
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(60);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getCJProducts(newPage, rowsPerPage);
  };

  const CustomPaginationItem = styled(PaginationItem)(({ theme }) => ({
  fontSize: '24px',
  borderRadius: '0', // Make buttons square
  '&.Mui-selected': {
    backgroundColor: '#3BB77E', // Change background color for selected item
  },
  '&:hover': {
    backgroundColor: '#D8F1E5', // Change background color on hover
  },
}));

  

  const prod = useSelector((state) => state.cjproducts.products.productslist) || [];
  const totalProd = useSelector((state) => state.cjproducts.products.totalProducts) || 60;
  const status = useSelector((state) => state.cjproducts.status);
  let { main, sub, low } = useParams();

  const setProductCat = () => {
    sessionStorage.setItem("mainCat", main);
    sessionStorage.setItem("subCat", sub);
    sessionStorage.setItem("lowCat", low);
  };

  const setCategories = () => {
    dispatch(
      updateCategory({
        company: "CJ",
        main_cat: main.replaceAll("_", " "),
        sub_cat: sub.replaceAll("_", " "),
        low_cat: low.replaceAll("_", " "),
      })
    );
  };

  const getCJProducts = (page, size) => {
    dispatch(fetchCJProducts({ page: page, pageSize: size }));
    window.scrollTo(0, 0);
  };

  const [data, setData] = useState([]);

  const context = useContext(MyContext);

  useEffect(() => {
    setCategories();
    getCJProducts(page, rowsPerPage);
    setProductCat();
  }, [sub, low]);

  const filterByBrand = (keyword) => {};

  const filterByPrice = (minValue, maxValue) => {};

  const filterByRating = (keyword) => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      {context.windowWidth < 992 && (
        <>
          {context.isopenNavigation === false && (
            <Button
              className="btn-g btn-lg w-100 filterBtn"
              onClick={() => context.openFilters()}
            >
              Filters
            </Button>
          )}
        </>
      )}

      <section className="listingPage">
        <div className="container-fluid">
          <div className="breadcrumb flex-column">
            <h1 className="text-capitalize">
              {low ? low.replaceAll("_", " ") : sub.replaceAll("_", " ")}
            </h1>
            <ul className="list list-inline mb-0">
              <li className="list-inline-item">
                <Link to={"/"}>{`Home >>`}</Link>
              </li>
              <li className="list-inline-item">
                <Link to={"/"}>{`${main.replaceAll("_", " ")} >>`} </Link>
              </li>

              <li className="list-inline-item">
                <Link to={"/"}>{` ${sub.replaceAll("_", " ")} >>`} </Link>
              </li>
              {low && (
                <li className="list-inline-item">
                  <Link to={""} className="text-capitalize">
                    {low.replaceAll("_", " ")}
                  </Link>
                </li>
              )}
            </ul>
          </div>

          <div className="listingData">
            <div className="row">
              <div
                className={`col-md-3 sidebarWrapper ${
                  context.isOpenFilters !== true && "click"
                }`}
              >
                {prod.length !== 0 && (
                  <Sidebar
                    data={prod}
                    currentCatData={prod}
                    filterByBrand={filterByBrand}
                    filterByPrice={filterByPrice}
                    filterByRating={filterByRating}
                  />
                )}
              </div>

              <div className="col-md-9 rightContent homeProducts pt-0">
                <div className="topStrip d-flex align-items-center">
                  <p className="mb-0">
                    We found <span className="text-success">{totalProd}</span> items for you!
                  </p>
                  <div className="ml-auto d-flex align-items-center">
                    <div className="tab_ position-relative">
                      <Button
                        className="btn_"
                        onClick={() => setisOpenDropDown(!isOpenDropDown)}
                      >
                        <GridViewOutlinedIcon /> Show: {showPerPage * 5}
                      </Button>
                      {isOpenDropDown !== false && (
                        <ul className="dropdownMenu">
                          <li>
                            <Button
                              className="align-items-center"
                              onClick={() => {
                                setisOpenDropDown(false);
                                setHhowPerPage(1);
                              }}
                            >
                              5
                            </Button>
                          </li>
                          <li>
                            <Button
                              className="align-items-center"
                              onClick={() => {
                                setisOpenDropDown(false);
                                setHhowPerPage(2);
                              }}
                            >
                              10
                            </Button>
                          </li>

                          <li>
                            <Button
                              className="align-items-center"
                              onClick={() => {
                                setisOpenDropDown(false);
                                setHhowPerPage(3);
                              }}
                            >
                              15
                            </Button>
                          </li>

                          <li>
                            <Button
                              className="align-items-center"
                              onClick={() => {
                                setisOpenDropDown(false);
                                setHhowPerPage(4);
                              }}
                            >
                              20
                            </Button>
                          </li>
                        </ul>
                      )}
                    </div>
                    <div className="tab_ ml-3 position-relative">
                      <Button
                        className="btn_"
                        onClick={() => setisOpenDropDown2(!isOpenDropDown2)}
                      >
                        <FilterListOutlinedIcon /> Sort by: Featured{" "}
                      </Button>
                      {isOpenDropDown2 !== false && (
                        <ul className="dropdownMenu">
                          <li>
                            <Button
                              className="align-items-center"
                              onClick={() => setisOpenDropDown2(false)}
                            >
                              Featured
                            </Button>
                          </li>
                          <li>
                            <Button
                              className="align-items-center"
                              onClick={() => setisOpenDropDown2(false)}
                            >
                              Price: Low to High
                            </Button>
                          </li>
                          <li>
                            <Button
                              className="align-items-center"
                              onClick={() => setisOpenDropDown2(false)}
                            >
                              Price: High to Low
                            </Button>
                          </li>
                          <li>
                            <Button
                              className="align-items-center"
                              onClick={() => setisOpenDropDown2(false)}
                            >
                              Release Date
                            </Button>
                          </li>
                          <li>
                            <Button
                              className="align-items-center"
                              onClick={() => setisOpenDropDown2(false)}
                            >
                              Avg. Rating
                            </Button>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div>

                <div className="productRow pl-4 pr-3">
                  {status === "loading" ? (
                    <div className="loader">
                      <img src={Loader} alt="Loader" />
                    </div>
                  ) : (
                    prod.length !== 0 &&
                    prod.map((item, index) => {
                      return (
                        <div className="item" key={index}>
                          <Product tag={item.type} item={item} />
                        </div>
                      );
                    })
                  )}
                </div>

                <Box sx={{ display: "flex", fontSize:'20px', justifyContent: "center", mt: 2 }}>
                  <Pagination
                    count={Math.ceil(totalProd / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                    color="primary"
                    renderItem={(item) => <CustomPaginationItem {...item} />}
                  />
                </Box>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Listing;
