import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  cart: []
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    updateCart: (state, action) => {
      if (Array.isArray(action.payload) && action.payload.length === 0) {
        state.cart = [];
      } else {
      if(!state.cart.some(cart => cart.merci_prod_name === action.payload.merci_prod_name)){
      state.cart = [...state.cart, action.payload]
      }
    }
  },
    deleteFromCart: (state, action) => {
      state.cart = state.cart.filter(item => item.id !== action.payload);
    },
    incerementCount: (state, action) => {
      const  id  = action.payload;
      const existingItemIndex = state.cart.findIndex(item => item.id === id);
      existingItemIndex >= 0 && state.cart[existingItemIndex].merci_count++;
    },
    decrementCount: (state, action) => {
      const id = action.payload;
      const existingItemIndex = state.cart.findIndex(item => item.id === id);
      existingItemIndex >= 0 && state.cart[existingItemIndex].merci_count >=1 && state.cart[existingItemIndex].merci_count--;
    },
  }
});
export const { updateCart, deleteFromCart, incerementCount, decrementCount } = cartSlice.actions;
export default cartSlice.reducer;
