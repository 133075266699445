import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";

import React, { useContext, useEffect, useState } from "react";
import { MyContext } from "../../App";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { rupee } from "../../constants/priceFormats";
import { getAddress } from "../../api/generalApi";
import './style.css'
import { createOrder, paymentMethod, paymentVerfication } from "../../api/PaymentApi";
import { generateUniqueId } from "../../utils/CartItems";
import { applyDiscount, getTotalandDeductions } from "../../utils/CartCal";
import { getCouponsByUserId } from "../../api/userApi";
import { deleteAllCartItem } from "../../utils/CartItems";

const Checkout = () => {

  const cartItems = useSelector((state) => state.cart.cart);
  const [address, setAddress] = useState([]);
  const [index, setIndex] = useState(null);
  const [addressSelected, setAddressSelected] = useState({});
  const [orginal, setOrginal] = useState("");
  const [discount, setDiscount] = useState("");
  const [disAvail, setDisAvail] = useState(true);
  const [disVal, setDisVal] = useState([]);
  const [discountPrice, setDiscountPrice] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch()

  const context = useContext(MyContext);
  const history = useNavigate();

  
  const fetchAddress = async (id) => {
    if(id !== '' && id !== null && id !== undefined){
    const res = await getAddress(id);
    setAddress(res);
    }
  };

  const totalAmount =
    cartItems.length !== 0 &&
    cartItems
      .map((item) => parseFloat(item.merci_mrp) * item.merci_count)
      .reduce((total, value) => total + value, 0);

  const [formFields, setformFields] = useState({
    name: "",
    mobile:'',
    pincode: "",
    address: "",
    landmark: "",
    city: "",
    state: "",
    alternatePhone: ""
  });


  
  const getCopuons = async () => {
    if(context.user){
    const coup = await getCouponsByUserId(context.user);
    return coup
    }
    return [];
  };

  
  useEffect(() => {
    const loadInitialData = async () => {
      await fetchAddress(context.user);
      const coup = await getCopuons();

      const { totalPrice, totalDeductions, orginalPrice } = getTotalandDeductions(cartItems);

      setOrginal(orginalPrice.toFixed(2));
      setDiscount(totalDeductions.toFixed(2));
      setDiscountPrice(totalPrice.toFixed(2));

      if (context.user && coup.length > 0) {
        const res = applyDiscount(totalDeductions, coup);

        if (res === "NA") {
          setDisAvail(false);
        } else {
          setDisVal(res);
        }
      }
    };

    loadInitialData();
  }, [context.user, cartItems]);


  const handlePayment = async (amount) => {
    if (index !== null && amount > 0) {
      const currency = "INR";
      const receipt = "0123456789";
      const res = await paymentMethod({
        amount: parseInt(amount * 100),
        currency,
        receipt,
      });

      var option = {
        key: "",
        amount,
        currency,
        name: "Merciplus Tech",
        description: "Test Transaction",
        image: "/img/merci_logo_sm.png",
        order_id: res.id,
        handler: async function (res) {
          const body = { ...res };
          const validateResponse = await paymentVerfication(body);

          if (validateResponse.status === 200) {
            const futureDate = new Date();
            futureDate.setDate(futureDate.getDate() + 7);

            const purchasedData = {
              ORID: generateUniqueId(),
              userId: context.user,
              orderID: validateResponse.orderId,
              paymentID: validateResponse.paymentId,
              address: {
                merci_name: addressSelected.merci_name,
                merci_mobile: addressSelected.merci_mobile,
                merci_pincode: addressSelected.merci_pincode,
                merci_locality: addressSelected.merci_locality,
                merci_address: addressSelected.merci_address,
                merci_city: addressSelected.merci_city,
                merci_state: addressSelected.merci_state,
                merci_landmark: addressSelected.merci_landmark,
              },
              cartData: cartItems.map((item) => ({
                merci_count: item.merci_count,
                id: item.id,
                merci_spu_id: item.merci_spu_id,
                merci_mrp: item.merci_mrp,
                merci_prod_name: item.merci_prod_name,
                merci_prod_img: item.merci_prod_img,
                status: 1,
                refund: false,
              })),
              total: amount,
              shippingFee: 0,
              discount: getTotalandDeductions(cartItems).totalDeductions,
              purchasedDate: new Date(),
              returnDate: futureDate,
              discountData: disVal,
            };
            await createOrder(purchasedData);
            alert("Your order is placed. Thankyou for shopping with us");
            deleteAllCartItem(dispatch)
            history("/");
          } else {
            alert(validateResponse.msg);
          }
        },
        notes: {
          address: "Merciplus Tech PVT LTD",
        },
        theme: {
          color: "#3399cc",
        },
      };
    } else {
      amount > 0
        ? alert("Please select an Address")
        : alert("Your Cart is Empty.Please shop");
    }

    var rzp1 = new window.Razorpay(option);
    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });

    rzp1.open();
  };


  const changeInput = (e) => {
    const { name, value } = e.target;

    setformFields(() => ({
      ...formFields,
      [name]: value,
    }));
  };

  const handleAddressChange = (ind) => {
    setIndex(ind);
    setAddressSelected(address[ind]);
  }

  return (
    <section className="cartSection mb-5 checkoutPage">
      <div className="container" style={{ margin: windowWidth < 992 && '0 0', maxWidth: windowWidth < 992 && '100%'}}>
        <form>
          <div className="row">
            <div className="col-md-8">
              <div className={windowWidth< 992 ? "col-md-12 mt-4" : "col-md-12 w-75 mt-4"}>
              
                <div className="card p-4">
                <h3>Saved Address</h3>
                  {address.map((add, ind) => {
                    return (
                      <React.Fragment key={ind} >
                        <div className="m-4 addresscontainer">
                        <input 
                            type="radio" 
                            className="radio-button"
                            checked={index === ind}
                            onChange={() => handleAddressChange(ind)} 
                        />
                        <div className="m-2 details gap-1">
                          <h4 className="text-dark mb-0">{add.merci_name}</h4>
                          <h5 className="text-dark mb-0">{add.merci_mobile}</h5>
                          <h5 className="text-dark mb-0">
                            {add.merci_address},{add.merci_locality},{add.merci_city},
                            {add.merci_state}-{add.merci_pincode}
                          </h5>
                        </div>
                      </div>
                      <hr/>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
              <div className={ windowWidth < 992 ? "form mt-4 shadow" : "form w-75 mt-4 shadow"}>
                <h3>Shopping Address</h3>
                <div className="form-group mb-3 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Enter Full Name"
                    variant="outlined"
                    className="w-100"
                    value={formFields.name}
                    onChange={changeInput}
                    name="name"
                  />
                </div>
                <div className="form-group mb-3 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Phone Number"
                    variant="outlined"
                    className="w-100"
                    value={formFields.mobile}
                    onChange={changeInput}
                    name="mobile"
                  />
                </div>

                <div className="form-group mb-3">
                  <TextField
                    id="outlined-basic"
                    label="Enter Pincode"
                    variant="outlined"
                    className="w-100"
                    value={formFields.pincode}
                    onChange={changeInput}
                    name="pincode"
                  />
                </div>
                <div className="form-group">
                  <TextField
                    id="outlined-basic"
                    label="Enter Full Address"
                    variant="outlined"
                    className="w-100"
                    multiline
                    rows={4}
                    value={formFields.address}
                    onChange={changeInput}
                    name="address"
                  />
                </div>
                <div className="form-group mb-3">
                  <TextField
                    id="outlined-basic"
                    label="Locality"
                    variant="outlined"
                    className="w-100"
                    value={formFields.locality}
                    onChange={changeInput}
                    name="locality"
                  />
                </div>
                <div className="form-group mb-3">
                  <TextField
                    id="outlined-basic"
                    label="City"
                    variant="outlined"
                    className="w-100"
                    value={formFields.city}
                    onChange={changeInput}
                    name="city"
                  />
                </div>
                <div className="form-group mb-3">
                  <TextField
                    id="outlined-basic"
                    label="State"
                    variant="outlined"
                    className="w-100"
                    value={formFields.state}
                    onChange={changeInput}
                    name="state"
                  />
                </div>
                <div className="form-group mb-3">
                  <TextField
                    id="outlined-basic"
                    label="Alternate Phone Number."
                    variant="outlined"
                    className="w-100"
                    value={formFields.alternatePhone}
                    onChange={changeInput}
                    name="alternatephoneNumber"
                  />
                </div>
                <Button className="btn-g btn-lg" onClick={() => {}}>
                  Add New Address
                </Button>
              </div>
            </div>

            <div className="col-md-4 cartRightBox pt-4">
              <div className="card p-4 ">
                <div className="d-flex align-items-center mb-4">
                  <h5 className="mb-0 text-light">Subtotal</h5>
                  <h3 className="ml-auto mb-0 font-weight-bold">
                    <span className="text-g">{rupee.format(totalAmount)}</span>
                  </h3>
                </div>

                <div className="d-flex align-items-center mb-4">
                  <h5 className="mb-0 text-light">Shipping</h5>
                  <h3 className="ml-auto mb-0 font-weight-bold">
                    <span>Free</span>
                  </h3>
                </div>

                <div className="d-flex align-items-center mb-4">
                  <h5 className="mb-0 text-light">GST (18%)</h5>
                  <h3 className="ml-auto mb-0 font-weight-bold">
                    <span>{rupee.format(totalAmount*0.18)}</span>
                  </h3>
                </div>

                <div className="d-flex align-items-center mb-4">
                  <h5 className="mb-0 text-light">Coupon Used</h5>
                  <h3 className="ml-auto mb-0 font-weight-bold">
                    <span>{rupee.format(discount)}</span>
                  </h3>
                </div>

                <div className="d-flex align-items-center mb-4">
                  <h5 className="mb-0 text-light">Total</h5>
                  <h3 className="ml-auto mb-0 font-weight-bold">
                    <span className="text-g">{rupee.format(totalAmount + (totalAmount*0.18))}</span>
                  </h3>
                </div>

                <br />
                <Button className="btn-g btn-lg" onClick={() => handlePayment(parseFloat(totalAmount))}>
                  Place Order
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Checkout;
