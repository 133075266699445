import React, { useEffect, useState } from "react";
import "./style.css";
import Rating from "@mui/material/Rating";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { getDiscountPercentage } from "../../utils/Percentages";
import { getPercentageList } from "../../api/PercentageApi";
import { rupee } from "../../constants/priceFormats";
import { handleCartItems } from "../../utils/CartItems";
import { useDispatch } from "react-redux";

const Product = (props) => {

  const [productData, setProductData] = useState();
  const [percentage, setPercentage] = useState(10);
  const dispatch = useDispatch();

  useEffect(() => {
    setProductData(props.item);
    getPercentages();
  }, [props.item]);

  const getPercentages = async () => {
    const percentValues = await getPercentageList("cj");
    setPercentage(percentValues.percentageList[0]);
  };
  const getRandomNumber = (min, max) => {
    return Math.random() * (max - min) + min;
  };

  const addToCart = (currentProduct) => {
    const transformedProduct = {
      id: currentProduct._id,
      merci_spu_id: currentProduct.merci_spu_id,
      merci_prod_name: currentProduct.merci_prod_name,
      merci_prod_img: JSON.parse(currentProduct.merci_prod_img.replace(/'/g, '"'))[0],
      merci_mrp:  currentProduct.merci_mrp,
      merci_merci_discount: currentProduct.merci_merci_discount
    };
    //console.log(transformedProduct);
    handleCartItems(transformedProduct, percentage, dispatch);
  };

  return (
    <div className="productThumb">
      {/* {
                props.tag !== null && props.tag !== undefined &&
                <span className={`badge ${props.tag}`}>{props.tag}</span>
            } */}

      {productData !== undefined && (
        <>
          <Link to={`/product/${productData.id}`}>
            <div className="imgWrapper">
              <div className="p-4 wrapper mb-3">
                <img
                  src={
                    JSON.parse(productData.merci_prod_img.replace(/'/g, '"'))[0]
                  }
                  className="w-100"
                  alt="img"
                />
              </div>
            </div>
          </Link>

          <div className="info">
            {/* <span className='d-block catName'>{productData.brand}</span> */}
            <h4 className="title">
              <Link>{productData.merci_prod_name.substr(0, 50) + "..."}</Link>
            </h4>
            <Rating
              name="half-rating-read"
              value={parseFloat(getRandomNumber(3.5, 5))}
              precision={0.5}
              readOnly
            />
            {/* <span className='brand d-block text-g'>By <Link className='text-g'>Merci Emart</Link></span> */}

            <div className="d-flex align-items-center mt-3">
              <div className="d-flex align-items-center w-100">
                <span className="price text-g font-weight-bold">
                  {rupee.format(getDiscountPercentage(percentage, productData.merci_mrp, productData.merci_merci_discount).finalValue)}
                </span>{" "}
                <span className="oldPrice ml-auto">
                  {rupee.format(getDiscountPercentage(percentage, productData.merci_mrp, productData.merci_merci_discount).increasedValue)}
                </span>
              </div>
            </div>

            <Button
              className="w-100 transition mt-3"
              onClick={() => addToCart(productData)}
            >
              <ShoppingCartOutlinedIcon />
              Add to Cart
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default Product;
