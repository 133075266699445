import React, { useState, useEffect, useRef } from "react";
import "../header/header.css";
import Logo from "../../assets/images/emart_small.png";
import SearchIcon from "@mui/icons-material/Search";
import Select from "../selectDrop/select";
import IconHeart from "../../assets/images/icon-heart.svg";
import IconCart from "../../assets/images/icon-cart.svg";
import IconUser from "../../assets/images/icon-user.svg";

import Button from "@mui/material/Button";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import LocalMallIcon from "@mui/icons-material/LocalMallOutlined";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import DiscountIcon from "@mui/icons-material/DiscountOutlined";

import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { IndianMenu } from "../../constants/IndianMenu";

import Nav from "./nav/nav";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";

import { MyContext } from "../../App";
import MenuIcon from "@mui/icons-material/Menu";
import Cookies from "js-cookie";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useSelector } from "react-redux";

const Header = (props) => {
  const [isOpenDropDown, setisOpenDropDown] = useState(false);
  const [isOpenAccDropDown, setisOpenAccDropDown] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isopenSearch, setOpenSearch] = useState(false);
  const [isOpenNav, setIsOpenNav] = useState(false);
  const wishlist = useSelector((state) => state.wishlist.wishlist.length);
  const cartItems = useSelector((state) => state.cart.cart.length);
  const navigate = useNavigate();

  const headerRef = useRef();
  const searchInput = useRef();
  const categories = [...new Set(IndianMenu.map((el) => el.main_cat))];
  const countryList = [
    "United States - Dollar ($)",
    "United Kingdom - Pound (\u00A3)",
    "Europe - Euro (\u20AC)",
  ];
  const context = useContext(MyContext);


  useEffect(() => {}, [context.cartItems]);

  const signOut = () => {
    Cookies.remove("merciemartuser");
    window.location.href = "/";
  };

  const openSearch = () => {
    setOpenSearch(true);
    searchInput.current.focus();
  };

  const closeSearch = () => {
    setOpenSearch(false);
    searchInput.current.blur();
    searchInput.current.value = "";
  };

  const openNav = () => {
    setIsOpenNav(true);
    context.setIsopenNavigation(true);
  };

  const closeNav = () => {
    setIsOpenNav(false);
    setisOpenAccDropDown(false);
    context.setIsopenNavigation(false);
  };

  return (
    <>
      <div className="headerWrapper" ref={headerRef}>
        <header>
          <div className="container-fluid" style={{zIndex: '1'}}>
            <div className="row">
              <div
                className="col-sm-2 part1 d-flex align-items-center"
                style={{ justifyContent: "center" }}
              >
                <Link to="/">
                  <img src={Logo} className="logo" alt="logo" />
                </Link>
                {windowWidth < 992 && (
                  <div className="ml-auto d-flex align-items-center">
                    <ul className="list list-inline mb-0 headerTabs pl-0 mr-4">
                      <li className="list-inline-item">
                        <span>
                          <Link to={"/cart"}>
                            {" "}
                            <img src={IconCart} alt="Icon" />
                            <span className="badge bg-success rounded-circle">
                              {cartItems}
                            </span>
                          </Link>
                        </span>
                      </li>
                    </ul>
                    <div className="navbarToggle mr-2" onClick={openNav}>
                      <MenuIcon />
                    </div>
                    {context.isLogin === true && (
                      <div
                        className="myAccDrop"
                        onClick={() => setisOpenAccDropDown(!isOpenAccDropDown)}
                      >
                        <PersonOutlineOutlinedIcon />
                      </div>
                    )}
                  </div>
                )}
              </div>

              {/*headerSearch start here */}
              <div className="col-sm-5 part2">
                <div
                  className={`headerSearch d-flex align-items-center ${
                    isopenSearch === true ? "open" : ""
                  }`}
                >
                  {/* {
                                            windowWidth < 992 &&
                                            <div className='countryWrapper mb-4 w-100'>
                                                <Select data={countryList} placeholder={'Your Location'} icon={<LocationOnOutlinedIcon style={{ opacity: '0.5' }} />} />
                                            </div>
                                        } */}

                  {windowWidth < 992 && (
                    <div class="closeSearch" onClick={closeSearch}>
                      <ArrowBackIosIcon />
                    </div>
                  )}
                  <Select
                    data={categories}
                    placeholder={"All Categories"}
                    icon={false}
                  />

                  <div className="search">
                    <input
                      type="text"
                      placeholder="Search for items..."
                      ref={searchInput}
                    />
                    <SearchIcon className="searchIcon cursor" />
                  </div>
                </div>
              </div>
              {/*headerSearch start here */}

              <div className="col-sm-5 d-flex align-items-center part3 res-hide">
                <div className="ml-auto d-flex align-items-center">
                  <div className="countryWrapper">
                    <Select
                      data={countryList}
                      isSearchSelect={false}
                      placeholder={"Indian INR (\u20B9)"}
                      icon={false}
                    />
                  </div>
                  <ClickAwayListener
                    onClickAway={() => setisOpenDropDown(false)}
                  >
                    <ul className="list list-inline mb-0 headerTabs">
                      <li className="list-inline-item">
                        <span>
                          <Link to={"/wishlist"}>
                            <img src={IconHeart} alt="Heart Icon" />
                            <span className="badge bg-success rounded-circle">
                              {wishlist}
                            </span>
                            Wishlist
                          </Link>
                        </span>
                      </li>
                      <li className="list-inline-item">
                        <span>
                          <Link to={"/cart"}>
                            <img src={IconCart} alt="Cart Icon" />
                            <span className="badge bg-success rounded-circle">
                              {cartItems}
                            </span>
                            Cart
                          </Link>
                        </span>
                      </li>

                      {context.isLogin === true ? (
                        <li className="list-inline-item">
                          <span
                            onClick={() => setisOpenDropDown(!isOpenDropDown)}
                          >
                            <img src={IconUser} alt="User Icon" />
                            Account
                          </span>

                          {isOpenDropDown !== false && (
                            <ul className="dropdownMenu">
                              <li>
                                <Button
                                  className="align-items-center"
                                  onClick={() => navigate("/myaccount")}
                                >
                                  <Person2OutlinedIcon /> My Account
                                </Button>
                              </li>
                              <li>
                                <Button onClick={() => navigate("/myorders")}>
                                  <LocalMallIcon />
                                  My Orders
                                </Button>
                              </li>
                              <li>
                                <Button onClick={() => navigate("/myaddress")}>
                                  <HomeIcon />
                                  My Address
                                </Button>
                              </li>
                              <li>
                                <Button onClick={() => navigate("/mycoupons")}>
                                  <DiscountIcon />
                                  My Coupons
                                </Button>
                              </li>
                              <li>
                                <Button onClick={() => navigate("/wishlist")}>
                                  <FavoriteBorderOutlinedIcon /> My Wishlist
                                </Button>
                              </li>
                              <li>
                                <Button onClick={signOut}>
                                  <LogoutOutlinedIcon /> Sign out
                                </Button>
                              </li>
                            </ul>
                          )}
                        </li>
                      ) : (
                        <li className="list-inline-item">
                          <Link to={"/signIn"}>
                            <Button className="btn btn-g">Sign In</Button>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </ClickAwayListener>
                </div>
              </div>
            </div>
          </div>
        </header>

        <Nav data={props.data} openNav={isOpenNav} closeNav={closeNav} />
      </div>

      <div
        className="afterHeader"
        style={{ paddingTop: windowWidth < 992 && "10rem" }}
      ></div>
      {isOpenAccDropDown !== false && (
        <>
          <div className="navbarOverlay" onClick={closeNav}></div>
          <ul className="dropdownMenu dropdownMenuAcc" onClick={closeNav}>
            <li>
              <Button
                className="align-items-center"
                onClick={() => navigate("/myaccount")}
              >
                <Person2OutlinedIcon /> My Account
              </Button>
            </li>
            <li>
              <Button onClick={() => navigate("/myorders")}>
                <LocalMallIcon />
                My Orders
              </Button>
            </li>
            <li>
              <Button onClick={() => navigate("/myaddress")}>
                <HomeIcon />
                My Address
              </Button>
            </li>
            <li>
              <Button onClick={() => navigate("/mycoupons")}>
                <DiscountIcon />
                My Coupons
              </Button>
            </li>
            <li>
              <Button onClick={() => navigate("/wishlist")}>
                <FavoriteBorderOutlinedIcon /> My Wishlist
              </Button>
            </li>
            <li>
              <Button onClick={signOut}>
                <LogoutOutlinedIcon /> Sign out
              </Button>
            </li>
          </ul>
        </>
      )}
    </>
  );
};

export default Header;
