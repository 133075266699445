/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useState,
  useEffect,
  useRef,
  useContext
} from "react";
import SliderBanner from "./slider/index";
import CatSlider from "../../components/catSlider";

import Banners from "../../components/banners";

import "./style.css";
import Product from "../../components/product";
import Banner4 from "../../assets/images/banner4.jpg";

import Slider from "react-slick";
import TopProducts from "./TopProducts";
import { MyContext } from "../../App";
import { IndianMenu } from "../../constants/IndianMenu";
import { fetchProducts } from "../../api/api";

const Home = (props) => {
  const [prodData, setprodData] = useState([]);
  const [activeTab, setactiveTab] = useState("Office & School Supplies");
  const [activeTabIndex, setactiveTabIndex] = useState(0);
  const [activeTabData, setActiveTabData] = useState([]);
  const [bestSells, setBestSells] = useState([]);
  const [topSells, setTopSells] = useState([]);
  const [topSellsOne, setTopSellsOne] = useState([]);
  const [topSellsTwo, setTopSellsTwo] = useState([]);
  const [topSellsThree, setTopSellsThree] = useState([]);

  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const productRow = useRef();
  const context = useContext(MyContext);

  var settings = {
    dots: false,
    infinite: context.windowWidth < 992 ? false : true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    fade: false,
    arrows: context.windowWidth < 992 ? false : true,
  };

  const getProducts = async (main_cat, sub_cat, low_cat, cate) => {
    const res = await fetchProducts(
      {
        main_cat: main_cat,
        sub_cat: sub_cat,
        low_cat: low_cat,
      },
      1,
      10
    );
    if(cate === 1) setActiveTabData(res.productslist || []);
    if(cate === 2) setBestSells(res.productslist || []);
    if(cate === 3) setTopSells(res.productslist || []);
    if(cate === 4) setTopSellsOne(res.productslist || []);
    if(cate === 5) setTopSellsTwo(res.productslist || []);
    if(cate === 6) setTopSellsThree(res.productslist || []);
  };

  const updateTabProducts = (main_cat, sub_cat, indact = 0, act = activeTab, cate) => {
    setIsLoadingProducts(true);
    setactiveTabIndex(indact);
    setactiveTab(act);
    getProducts(main_cat, sub_cat, act, cate);
    setIsLoadingProducts(false);
  };

  useEffect(() => {
    getProducts("Computer & Office", "Office Electronics", activeTab, 1);
    getProducts("Toys, Kids & Babies", "Toys & Hobbies", "Stuffed & Plush Animals", 2);
    getProducts("Bags & Shoes", "Women's Shoes", "Woman Sandals", 3);
    getProducts("Toys, Kids & Babies", "Toys & Hobbies", "Electronic Pets", 4);
    getProducts("Toys, Kids & Babies", "Baby Clothing", "Baby Dresses", 5);
    getProducts("Toys, Kids & Babies", "Baby Clothing", "Baby Accessories", 6);
    
  }, [activeTab]);

  

  

  return (
    <div style={{ display: "block" }}>
      <SliderBanner />
      <CatSlider data={prodData} />

      <Banners />

      <section className="homeProducts homeProductWrapper">
        <div className="container-fluid">
          <div className="d-flex align-items-center homeProductsTitleWrap">
            <h2 className="hd mb-0 mt-0 res-full">Popular Products</h2>
            <ul className="list list-inline ml-auto filterTab mb-0 res-full">
              {[
                ...new Set(
                  IndianMenu.filter(
                    (el) =>
                      el.main_cat === "Computer & Office" &&
                      el.sub_cat === "Office Electronics"
                  )
                ),
              ].map((cat, index) => {
                return (
                  <li className="list list-inline-item" key={index}>
                    <a
                      className={`cursor text-capitalize ${ activeTabIndex === index && "act"}`}
                      onClick={() => {
                        updateTabProducts("Computer & Office", index, cat.low_cat, 1);
                      }}
                    >
                      {cat.low_cat}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>

          <div
            className={`productRow ${isLoadingProducts === true && "loading"}`}
            ref={productRow}
          >
            {activeTabData.map((item, index) => {
                return (
                  <div className="item" key={index}>
                    <Product tag={item.type} item={item} />
                  </div>
                );
              })}
          </div>
        </div>
      </section>

      <section className="homeProducts homeProductsRow2 pt-0">
        <div className="container-fluid">
          <div className="d-flex align-items-center">
            <h2 className="hd mb-0 mt-0">Daily Best Sells</h2>
          </div>

          <br className="res-hide" />
          <br className="res-hide" />
          <div className="row">
            <div className="col-md-3 pr-5 res-hide">
              <img src={Banner4} className="w-100" alt="Banner" />
            </div>

            <div className="col-md-9">
              <Slider {...settings} className="prodSlider">
                {bestSells.length !== 0 &&
                  bestSells.map((item, index) => {
                    return (
                      <div className="item" key={index}>
                        <Product tag={item.type} item={item} />
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </div>
        </div>
      </section>

      <section className="topProductsSection">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <TopProducts title="Woman Sandals"  prods={topSells}/>
            </div>

            <div className="col">
              <TopProducts title="Electronic Pets" prods={topSellsOne}/>
            </div>

            <div className="col">
              <TopProducts title="Baby Dresses" prods={topSellsTwo}/>
            </div>

            <div className="col">
              <TopProducts title="Baby Accessories" prods={topSellsThree}/>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
