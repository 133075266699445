import React, { useContext, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import { Button } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { MyContext } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import { rupee } from "../../constants/priceFormats";
import { deleteWishlistItem } from "../../utils/Wishlist";

const Wishlist = () => {
  const context = useContext(MyContext);
  const dispatch = useDispatch();

  const wishlistState = useSelector(state => state.wishlist.wishlist);
  const wishlist = useMemo(() => wishlistState || [], [wishlistState]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [wishlistState]);

  return (
    <>
      {context.windowWidth > 992 && (
        <div className="breadcrumbWrapper mb-4">
          <div className="container-fluid">
            <ul className="breadcrumb breadcrumb2 mb-0">
              <li>
                <Link to={"/"}>Home</Link>
              </li>
              <li>Shop</li>
              <li>Wishlist</li>
            </ul>
          </div>
        </div>
      )}

      <section className="cartSection mb-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex align-items-center w-100">
                <div className="left">
                  <h1 className="hd mb-0">Your Wishlist Items</h1>
                  <p>
                    There are <span className="text-g">{wishlist.length}</span> products in your
                    wishlist items
                  </p>
                </div>
              </div>

              <div className="cartWrapper mt-4">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th>Unit Price</th>
                        <th>Add to cart</th>
                        <th>Remove</th>
                      </tr>
                    </thead>

                    <tbody>
                      {wishlist.length !== 0 &&
                        wishlist.map((item, index) => {
                          return (
                            <tr>
                              <td width={"50%"}>
                                <div className="d-flex align-items-center">
                                  <div className="img">
                                    <Link to={`/product/${item.id}`}>
                                      <img
                                        src={
                                          item.merci_prod_img + "?im=Resize=(100,100)"
                                        }
                                        className="w-100"
                                        alt="img"
                                      />
                                    </Link>
                                  </div>

                                  <div className="info pl-4">
                                    <Link to={`/product/${item.id}`}>
                                      <h4>{item.merci_prod_name}</h4>
                                    </Link>
                                  </div>
                                </div>
                              </td>
                              
                              <td width="20%">
                                <span>
                                { rupee.format(parseFloat(item.merci_mrp))}
                                </span>
                              </td>


                              <td>
                                <span
                                  className="cursor"
                                  onClick={() =>
                                    context.removeItemsFromCart(item.id)
                                  }
                                >
                                  <Button className="btn-g">Add to Cart</Button>
                                </span>
                              </td>
                              <td>
                                <span
                                  className="cursor"
                                  onClick={() => deleteWishlistItem(item.id, dispatch)
                                  }
                                >
                                  <Button className="btn-g">Delete</Button>
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>

              <br />

              <div className="d-flex align-items-center">
                <Link to="/">
                  <Button className="btn-g">
                    <KeyboardBackspaceIcon /> Continue Shopping
                  </Button>
                </Link>
                {/* <Button className='btn-g ml-auto' onClick={updateCartData}>
                    <RefreshIcon /> Update Cart</Button> */}
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default Wishlist;
