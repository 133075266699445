import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {getUser} from '../api/userApi';

const initialState = {
  value: 0,
  user: {},
};

export const getUserfromId = createAsyncThunk(
  'getUser',
  async (id) => {
    const response = await getUser(id);
    return response;
  }
);

export const UserSlice = createSlice({
  name: 'userslice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getUserfromId.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUserfromId.fulfilled, (state, action) => {
        state.status = 'idle';
        state.user = action.payload;
      });
  },
});

// export const categories = (state) => state.categories.value;

export default UserSlice.reducer;
