import axios from 'axios'
import { ApiURL } from '../constants/constants';

export const fetchCategory = async () => {
    const result = await axios.get(`${ApiURL}/categories`);
    return result.data;
}

export const fetchProducts = async (cate, page, pageSize ) => {
    const catData = {
        main_cat: cate.main_cat,
        sub_cat: cate.sub_cat,
        low_cat: cate.low_cat
    }
    const result = await axios.post(`${ApiURL}/cjproducts?page=${page}&pageSize=${pageSize}`,catData);
    return result.data;
}

export const fetchProduct = async (skuid_val) => {
    const result = await axios.post(`${ApiURL}/cjproduct`,{sku: skuid_val});
    return result.data;
}

export const fetchProductById = async (id) => {
    const result = await axios.post(`${ApiURL}/cjproducts/${id}`);
    return result.data;
}