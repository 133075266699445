import React, { useEffect, useState, createContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./responsive.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from "./components/header/header";
import Loader from "./assets/images/loading.gif";

import data from "./data/data";
import EmartRoutes from "./EmartRoutes";
import Footer from "./components/footer/footer";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { updateWishlist } from "./features/wishlistSlice";
import { updateCart } from "./features/cartSlice";

const MyContext = createContext();

const App = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [user, setUser] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isopenNavigation, setIsopenNavigation] = useState(false);
  const [cartTotalAmount, setCartTotalAmount] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    signIn();
    const cookie = Cookies.get("emartWishlist");
    if (cookie) {
      let wishlist = JSON.parse(cookie);

      // Remove duplicates by product name
      const uniqueWishlist = wishlist.filter(
        (item, index, self) =>
          index ===
          self.findIndex((t) => t.merci_prod_name === item.merci_prod_name)
      );
      uniqueWishlist.map((wish) => dispatch(updateWishlist(wish)));
    } else {
      dispatch(updateWishlist([]));
    }

    const cookiecart = Cookies.get("emartCart");
    if (cookiecart) {
      let cartItem = JSON.parse(cookiecart);

      // Remove duplicates by product name
      const uniqueCart = cartItem.filter(
        (item, index, self) =>
          index ===
          self.findIndex((t) => t.merci_prod_name === item.merci_prod_name)
      );
      uniqueCart.map((cart) => dispatch(updateCart(cart)));
    } else {
      dispatch(updateCart([]));
    }
  }, [dispatch]);

  const removeItemsFromCart = async (id) => {};

  const emptyCart = () => {
    setCartItems([]);
  };

  const signIn = () => {
    if (
      Cookies.get("merciemartuser") !== null &&
      Cookies.get("merciemartuser") !== undefined
    ) {
      setIsLogin(true);
      setUser(Cookies.get("merciemartuser"))
    } else {
      setIsLogin(false);
    }
  };

  const signOut = () => {};

  const openFilters = () => {};

  const addToCart = () => {};

  const value = {
    cartItems: [],
    isLogin,
    user,
    windowWidth,
    isOpenFilters: false,
    addToCart,
    removeItemsFromCart,
    emptyCart,
    signOut,
    signIn,
    openFilters,
    isopenNavigation,
    setIsopenNavigation,
    setCartTotalAmount,
    cartTotalAmount,
    setCartItems,
  };
  return (
    <BrowserRouter>
      <MyContext.Provider value={value}>
        {isLoading === true && (
          <div className="loader">
            <img src={Loader} alt="Loader" />
          </div>
        )}

        <Header data={data.productData} />
        <EmartRoutes />
        <Footer />
      </MyContext.Provider>
    </BrowserRouter>
  );
};

export default App;

export { MyContext };
