import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  category: {
    company: '',
    main_cat: '',
    sub_cat: '',
    low_cat: ''
  }
};

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    updateCategory: (state, action) => {
      state.category = action.payload;
    },
  }
});
export const { updateCategory } = categorySlice.actions;
export default categorySlice.reducer;
