import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Rating from "@mui/material/Rating";
import { Button } from "@mui/material";
import QuantityBox from "../../components/quantityBox";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { MyContext } from "../../App";
import { useSelector, useDispatch } from "react-redux";
import { rupee } from "../../constants/priceFormats";
import { deleteCartItem } from "../../utils/CartItems";

const Cart = () => {
  const context = useContext(MyContext);
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.cart);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [cartItems]);

  return (
    <>
      {context.windowWidth > 992 && (
        <div className="breadcrumbWrapper mb-4">
          <div className="container-fluid">
            <ul className="breadcrumb breadcrumb2 mb-0">
              <li>
                <Link to={"/"}>Home</Link>
              </li>
              <li>Shop</li>
              <li>Cart</li>
            </ul>
          </div>
        </div>
      )}

      <section className="cartSection mb-5">
        {console.log('cartItems', cartItems)}
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <div className="d-flex align-items-center w-100">
                <div className="left">
                  <h1 className="hd mb-0">Your Cart</h1>
                  <p>
                    There are <span className="text-g">{cartItems.length}</span>{" "}
                    products in your cart
                  </p>
                </div>
              </div>
              <div className="cartWrapper mt-4">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th>Unit Price</th>
                        <th>Quantity</th>
                        <th>Subtotal</th>
                        <th>Remove</th>
                      </tr>
                    </thead>

                    <tbody>
                      {cartItems.length !== 0 &&
                        cartItems.map((item, index) => {
                          return (
                            <tr key={item.merci_prod_name}>
                              <td width={"50%"}>
                                <div className="d-flex align-items-center">
                                  <div className="img">
                                    <Link to={`/product/${item.id}`}>
                                      <img
                                        src={
                                          item.merci_prod_img +
                                          "?im=Resize=(100,100)"
                                        }
                                        alt="img"
                                        className="w-100"
                                      />
                                    </Link>
                                  </div>

                                  <div className="info pl-4">
                                    <Link to={`/product/${item.id}`}>
                                      <h4>{item.merci_prod_name}</h4>
                                    </Link>
                                  </div>
                                </div>
                              </td>

                              <td width="20%">
                                <span>{rupee.format(item.merci_mrp)}</span>
                              </td>

                              <td>
                                <QuantityBox
                                  id={item.id}
                                  count={item.merci_count}
                                />
                              </td>
                              <td>
                                <span className="text-g">
                                  {rupee.format(
                                    parseFloat(item.merci_mrp) *
                                      parseFloat(item.merci_count)
                                  )}
                                </span>
                              </td>

                              <td align="center">
                                <span
                                  className="cursor"
                                  onClick={() =>
                                    deleteCartItem(item.id, dispatch)
                                  }
                                >
                                  <DeleteOutlineOutlinedIcon />
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>

              <br />

              <div className="d-flex align-items-center">
                <Link to="/">
                  <Button className="btn-g">
                    <KeyboardBackspaceIcon /> Continue Shopping
                  </Button>
                </Link>
                {/* <Button className='btn-g ml-auto' onClick={updateCartData}>
                    <RefreshIcon /> Update Cart</Button> */}
              </div>
            </div>

            <div className="col-md-4 cartRightBox">
              <div className="card p-4 ">
                <div className="d-flex align-items-center mb-4">
                  <h5 className="mb-0 text-light">Subtotal</h5>
                  <h3 className="ml-auto mb-0 font-weight-bold">
                    <span className="text-g">
                      {cartItems.length !== 0 &&
                        rupee.format(cartItems
                          .map(
                            (item) =>
                              parseFloat(item.merci_mrp) * item.merci_count
                          )
                          .reduce((total, value) => total + value, 0))}
                    </span>
                  </h3>
                </div>

                <div className="d-flex align-items-center mb-4">
                  <h5 className="mb-0 text-light">Shipping</h5>
                  <h3 className="ml-auto mb-0 font-weight-bold">
                    <span>Free</span>
                  </h3>
                </div>

                <div className="d-flex align-items-center mb-4">
                  <h5 className="mb-0 text-light">Estimate for</h5>
                  <h3 className="ml-auto mb-0 font-weight-bold">India</h3>
                </div>

                <div className="d-flex align-items-center mb-4">
                  <h5 className="mb-0 text-light">Total</h5>
                  <h3 className="ml-auto mb-0 font-weight-bold">
                    <span className="text-g">
                      {cartItems.length !== 0 &&
                        rupee.format(cartItems
                          .map(
                            (item) =>
                              parseFloat(item.merci_mrp) * item.merci_count
                          )
                          .reduce((total, value) => total + value, 0))}
                    </span>
                  </h3>
                </div>

                <br />
                <Link to={"/checkout"}>
                  <Button className="btn-g btn-lg">Proceed To CheckOut</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cart;
