import React from "react";
import { Link } from "react-router-dom";
import "react-range-slider-input/dist/style.css";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import HomeIcon from '@mui/icons-material/HomeOutlined';
import DiscountIcon from '@mui/icons-material/DiscountOutlined';

const Usersidebar = () => {

  return (
    <>
      <div className={`sidebar open`}>
        <div className="card border-0 shadow res-hide">
          <h3>Welcome </h3>
          <div className="catList">
            <Link to={`/myaccount`}>
              <div className="catItem d-flex align-items-center">
                <span className="img" style={{color: '#3BB77E'}}><Person2OutlinedIcon /></span>
                <h4 className="mb-0 ml-3 mr-3 text-capitalize">Account</h4>
              </div>
            </Link>
            <Link to={`/myorders`}>
              <div className="catItem d-flex align-items-center">
                <span className="img" style={{color: '#3BB77E'}}><FavoriteBorderOutlinedIcon /></span>
                <h4 className="mb-0 ml-3 mr-3 text-capitalize">Orders</h4>
              </div>
            </Link>
            <Link to={`/myaddress`}>
              <div className="catItem d-flex align-items-center">
                <span className="img" style={{color: '#3BB77E'}}><HomeIcon /></span>
                <h4 className="mb-0 ml-3 mr-3 text-capitalize">Address</h4>
              </div>
            </Link>
            <Link to={`/mycoupons`}>
              <div className="catItem d-flex align-items-center">
                <span className="img" style={{color: '#3BB77E'}}><DiscountIcon /></span>
                <h4 className="mb-0 ml-3 mr-3 text-capitalize">Coupons</h4>
              </div>
            </Link>
            <Link to={`/wishlist`}>
              <div className="catItem d-flex align-items-center">
                <span className="img" style={{color: '#3BB77E'}}><FavoriteBorderOutlinedIcon/></span>
                <h4 className="mb-0 ml-3 mr-3 text-capitalize">Wishlist</h4>
              </div>
            </Link>
            <Link to={`/myaccount`}>
              <div className="catItem d-flex align-items-center">
                <span className="img" style={{color: '#3BB77E'}}><LogoutOutlinedIcon /></span>
                <h4 className="mb-0 ml-3 mr-3 text-capitalize">Signout</h4>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Usersidebar;
