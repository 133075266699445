// export const ApiURL = "https://merci-world.onrender.com/api/doba"


// export const URL = 'http://localhost:5000'
// export const ApiURL = "http://localhost:5000/api/cj"
// export const GlobalUserURL = "http://localhost:5000/api/globalUser"
// export const PERCENTAGEURL = 'http://localhost:5000/api/percentage'
// export const GENERALURL = 'http://localhost:5000/api/general'
// export const PAYMENTURL = 'http://localhost:5000/api/order/'
// export const UPLOADURL = 'http://localhost:5000/api/avatar/'
// export const RATINGSURL = 'http://localhost:5000/api/ratings'
// export const COUPONURL = "http://localhost:5000/api/coupons"

export const URL = 'https://merciworld.onrender.com'
export const ApiURL = "https://merciworld.onrender.com/api/cj"
export const GlobalUserURL = "https://merciworld.onrender.com/api/globalUser"
export const PERCENTAGEURL = 'https://merciworld.onrender.com/api/percentage'
export const GENERALURL = 'https://merciworld.onrender.com/api/general'
export const PAYMENTURL = 'https://merciworld.onrender.com/api/order/'
export const UPLOADURL = 'https://merciworld.onrender.com/api/avatar/'
export const RATINGSURL = 'https://merciworld.onrender.com/api/ratings'
export const COUPONURL = "https://merciworld.onrender.com/api/coupons"