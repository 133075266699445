import React, { useState, useEffect } from "react";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { decrementCount, incerementCount } from "../../features/cartSlice";
import { useDispatch } from "react-redux";

const QuantityBox = ({ id, count }) => {

    const dispatch = useDispatch();
 
  return (
    <div className="addCartSection pt-4 pb-4 d-flex align-items-center ">
      <div className="counterSec mr-3">
        <input type="number" value={count} readOnly/>
        <span className="arrow plus" onClick={() => dispatch(incerementCount(id))}>
          <KeyboardArrowUpIcon />
        </span>

        <span className="arrow minus" onClick={() => dispatch(decrementCount(id))}>
          <KeyboardArrowDownIcon />
        </span>
      </div>
    </div>
  );
};

export default QuantityBox;
