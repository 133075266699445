import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Privacy = () => {
    useEffect(() => { window.scrollTo(0, 0); }, []);
  return (
    <section className="listingPage">
      <div className="container-fluid">
        <div className="breadcrumb flex-column">
          <h1 className="text-capitalize">Privacy</h1>
          <ul className="list list-inline mb-0">
            <li className="list-inline-item">
              <Link to={"/"}>Home</Link>
            </li>
          </ul>
        </div>

        <div className="ps-faqs">
        <div className="m-4 p-2 w-75">
          <div style={{textAlign: 'center'}}>
            <h1>Privacy Policy</h1>
          </div>
          <div className="ps-section__content">
            <div className="table-responsive">
              <div>

              <h3>1. Introduction</h3>
                <p>
                  Welcome to Merciplus Tech Private Limited (“Company”, “we”, “our”, “us”).
                  We are committed to protecting your personal information and
                  your right to privacy. This Privacy Policy explains how we
                  collect, use, disclose, and safeguard your information when
                  you visit our website https://www.merciemart.com (the “Site”) and use
                  our dropshipping services (collectively, “Services”). Please
                  read this privacy policy carefully. If you do not agree with
                  the terms of this privacy policy, please do not access the
                  Site or use the Services.
                </p>

                <h3>2. Information We Collect</h3>
                <p>
                  We may collect information about you in a variety of ways. The
                  information we may collect on the Site includes:
                </p>

                <h3>2.1 Personal Data</h3>
                <p>
                  Personally identifiable information, such as your name,
                  shipping address, email address, and telephone number, and
                  demographic information, such as your age, gender, hometown,
                  and interests, that you voluntarily give to us when you
                  register on the Site, place an order, or when you choose to
                  participate in various activities related to the Site and our
                  Services.
                </p>

                <h3>2.2 Payment Information</h3>
                <p>
                  Financial information, such as data related to your payment
                  method (e.g., valid credit card number, card brand, expiration
                  date) that we may collect when you purchase, order, return,
                  exchange, or request information about our Services from the
                  Site. We do not store any financial information.
                </p>

                <h3>2.3 Data from Social Networks</h3>
                <p>
                  User information from social networking sites, such as Facebook, Instagram, 
                  including your name, your social network
                  username, location, gender, birth date, email address, profile
                  picture, and public data for contacts, if you connect your
                  account to such social networks.
                </p>

                <h3>2.4 Mobile Device Data</h3>
                <p>
                  Device information, such as your mobile device ID, model, and
                  manufacturer, and information about the location of your
                  device, if you access the Site from a mobile device.
                </p>

                <h3>2.5 Third-Party Data</h3>
                <p>
                  Information from third parties, such as personal information
                  or network friends, if you connect your account to the third
                  party and grant the Site permission to access this
                  information.
                </p>

                <h3>3. Use of Your Information</h3>
                <p>We use the information we collect in the following ways:</p>
                <ul>
                  <li>To facilitate account creation and login process.</li>
                  <li>To process your transactions.</li>
                  <li>To manage your orders.</li>
                  <li>To send you order confirmations and invoices.</li>
                  <li>
                    To send administrative information, such as information
                    regarding the Site and changes to our terms, conditions, and
                    policies.
                  </li>
                  <li>
                    To prevent fraudulent transactions, monitor against theft,
                    and protect against criminal activity.
                  </li>
                  <li>
                    To fulfill and manage purchases, orders, payments, and other
                    transactions related to the Site.
                  </li>
                  <li>
                    To deliver targeted advertising, newsletters, and other
                    information regarding promotions and the Site to you.
                  </li>
                  <li>To improve our Services and the Site.</li>
                </ul>

                <h3>4. Disclosure of Your Information</h3>
                <p>
                  We may share information we have collected about you in
                  certain situations. Your information may be disclosed as
                  follows:
                </p>

                <h3>4.1 By Law or to Protect Rights</h3>
                <p>
                  If we believe the release of information about you is
                  necessary to respond to legal process, to investigate or
                  remedy potential violations of our policies, or to protect the
                  rights, property, and safety of others, we may share your
                  information as permitted or required by any applicable law,
                  rule, or regulation.
                </p>

                <h3>4.2 Business Transfers</h3>
                <p>
                  We may share or transfer your information in connection with,
                  or during negotiations of, any merger, sale of company assets,
                  financing, or acquisition of all or a portion of our business
                  to another company.
                </p>

                <h3>4.3 Third-Party Service Providers</h3>
                <p>
                  We may share your information with third parties that perform
                  services for us or on our behalf, including payment
                  processing, data analysis, email delivery, hosting services,
                  customer service, and marketing assistance.
                </p>

                <h3>4.4 Affiliates</h3>
                <p>
                  We may share your information with our affiliates, in which
                  case we will require those affiliates to honor this Privacy
                  Policy.
                </p>

                <h3>4.5 Business Partners</h3>
                <p>
                  We may share your information with our business partners to
                  offer you certain products, services, or promotions.
                </p>

                <h3>5. Security of Your Information</h3>
                <p>
                  We use administrative, technical, and physical security
                  measures to help protect your personal information. While we
                  have taken reasonable steps to secure the personal information
                  you provide to us, please be aware that despite our efforts,
                  no security measures are perfect or impenetrable, and no
                  method of data transmission can be guaranteed against any
                  interception or other type of misuse.
                </p>

                <h3>6. Policy for Children</h3>
                <p>
                  We do not knowingly solicit information from or market to
                  children under the age of 13. If we learn that we have
                  collected information from a child under age 13 without
                  verification of parental consent, we will delete that
                  information as quickly as possible.
                </p>

                <h3>7. Changes to This Privacy Policy</h3>
                <p>
                  We may update this Privacy Policy from time to time in order
                  to reflect changes to our practices or for other operational,
                  legal, or regulatory reasons. Any changes we make will be
                  effective immediately upon notice, which we may provide by
                  posting the new policy on the Site. Your continued use of the
                  Site following the posting of changes constitutes your
                  acceptance of such changes.
                </p>

                <h3>8. Contact Us</h3>
                <p>
                  If you have questions or comments about this Privacy Policy,
                  please contact us at:
                </p>
                <p>
                  Merciplus Tech Private Limited <br />
                  contact@merciemart.com <br />
                </p>

                <p>
                  By using the Site and our Services, you consent to the terms
                  of this Privacy Policy.
                </p>
                </div>
          </div>
          </div>
        </div>
      </div>

      </div>
    </section>
  );
};

export default Privacy;
