import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Tandc = () => {
    useEffect(() => { window.scrollTo(0, 0); }, []);
  return (
    <section className="listingPage">
      <div className="container-fluid">
        <div className="breadcrumb flex-column">
          <h1 className="text-capitalize">Terms and COnditions</h1>
          <ul className="list list-inline mb-0">
            <li className="list-inline-item">
              <Link to={"/"}>Home</Link>
            </li>
          </ul>
        </div>

        <div className="ps-faqs">
        <div className="m-4 p-2 w-75">
          <div style={{textAlign: 'center'}}>
            <h1>Terms and Conditions</h1>
          </div>
          <div className="ps-section__content">
            <div className="table-responsive">
              <div>

              
              <h3>1. Introduction</h3>
                <p>
                  Welcome to Merciplus Tech Private Limited (“Company”, “we”, “our”, “us”).
                  These Terms and Conditions (“Terms”, “Terms and Conditions”)
                  govern your use of our website located at https://www.merciemart.com
                  (the “Site”) and our dropshipping services (collectively,
                  “Services”). By accessing the Site or using our Services, you
                  agree to be bound by these Terms. If you do not agree to these
                  Terms, please do not use the Site or Services.
                </p>

                <h3>2. Use of Our Services</h3>
                <p>
                  By using our Services, you represent and warrant that you are
                  at least 18 years old and capable of forming a binding
                  contract with us. You agree to provide accurate, current, and
                  complete information during the registration process and to
                  update such information to keep it accurate, current, and
                  complete. You are responsible for safeguarding your account
                  information and for any activities or actions under your
                  account.
                </p>

                <h3>3. Orders and Payments</h3>
                <p>
                  All orders are subject to acceptance and availability. We
                  reserve the right to refuse or cancel any order for any
                  reason, including limitations on quantities available for
                  purchase, inaccuracies, or errors in product or pricing
                  information. Prices for our products are subject to change
                  without notice.
                </p>
                <p>
                  You agree to pay all charges associated with your orders,
                  including any applicable taxes and shipping fees. Payments
                  must be made by a valid credit or debit card or other payment
                  method accepted by us. By providing payment information, you
                  represent and warrant that you have the legal right to use the
                  payment method provided.
                </p>

                <h3>4. Shipping and Delivery</h3>
                <p>
                  We will make every effort to deliver your orders within the
                  estimated delivery time. However, we are not responsible for
                  any delays caused by events beyond our control, including but
                  not limited to customs delays, shipping carrier delays, or
                  force majeure events. Title and risk of loss for the products
                  pass to you upon our delivery to the shipping carrier. Orders 
                  will be delivered typically between 3 to 7 days. 
                  If in case any delays in the delivery, a detailed explanation of the 
                  event will be sent to you via email.
                </p>

                <h3>5. Returns and Refunds</h3>
                <p>
                  Our return and refund policy is governed by our Returns
                  Policy, which is incorporated into these Terms by reference.
                  Please review our Returns Policy for detailed information on
                  our return and refund procedures.
                </p>

                <h3>6. Intellectual Property</h3>
                <p>
                  All content on the Site, including but not limited to text,
                  graphics, logos, images, and software, is the property of
                  Merciplus Tech Private Limited or its content suppliers and is protected
                  by international copyright, trademark, and other intellectual
                  property laws. You may not use, reproduce, modify, or
                  distribute any content from the Site without our prior written
                  consent.
                </p>

                <h3>7. Prohibited Activities</h3>
                <p>
                  You agree not to engage in any of the following prohibited
                  activities:
                </p>
                <ul>
                  <li>Violating any applicable laws or regulations.</li>
                  <li>
                    Infringing the intellectual property rights of others.
                  </li>
                  <li>
                    Engaging in any activity that could harm or interfere with
                    the operation of the Site or Services.
                  </li>
                  <li>
                    Misrepresenting your identity or affiliation with any person
                    or entity.
                  </li>
                  <li>
                    Using the Site or Services for any unauthorized or illegal
                    purpose.
                  </li>
                </ul>

                <h3>8. Disclaimer of Warranties</h3>
                <p>
                  The Site and Services are provided on an "as is" and "as
                  available" basis without any warranties of any kind, either
                  express or implied. We disclaim all warranties, including but
                  not limited to implied warranties of merchantability, fitness
                  for a particular purpose, and non-infringement. We do not
                  warrant that the Site or Services will be uninterrupted,
                  error-free, or free of viruses or other harmful components.
                </p>

                <h3>9. Limitation of Liability</h3>
                <p>
                  To the fullest extent permitted by law, in no event shall
                  Merciplus Tech Private Limited, its affiliates, or their respective
                  directors, employees, or agents be liable for any indirect,
                  incidental, special, consequential, or punitive damages,
                  including but not limited to lost profits, loss of data, or
                  other damages arising out of or in connection with your use of
                  the Site or Services.
                </p>

                <h3>10. Indemnification</h3>
                <p>
                  You agree to indemnify, defend, and hold harmless Merciplus Tech Private Limited,
                   its affiliates, and their respective directors,
                  employees, and agents from and against any and all claims,
                  liabilities, damages, losses, or expenses arising out of or in
                  any way connected with your access to or use of the Site or
                  Services, or your violation of these Terms.
                </p>

                <h3>11. Governing Law</h3>
                <p>
                  These Terms and any disputes arising out of or related to them
                  shall be governed by and construed in accordance with the laws
                  of India, without regard to its conflict of law
                  principles.
                </p>

                <h3>12. Changes to These Terms</h3>
                <p>
                  We reserve the right to modify these Terms at any time. Any
                  changes will be effective immediately upon posting the revised
                  Terms on the Site. Your continued use of the Site or Services
                  after the posting of changes constitutes your acceptance of
                  such changes.
                </p>

                <h3>13. Contact Us</h3>
                <p>
                  If you have any questions about these Terms, please contact us
                  at:
                </p>
                <p>
                  Merciplus Tech Private Limited <br />
                  contact@merciemart.com <br />
                </p>

                <p>
                  By using the Site and our Services, you agree to be bound by
                  these Terms and Conditions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      </div>
    </section>
  );
};

export default Tandc;
