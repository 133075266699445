import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Usersidebar from "../../components/Usersidebar";
import { MyContext } from "../../App";
import { getUser } from "../../api/userApi";
import {
  Box,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";

const Account = () => {
  const [user, setUser] = useState({});
  const context = useContext(MyContext);

  const [gender, setGender] = useState(user.merci_gender || "");
  const [dob, setDob] = useState(user.merci_dob || "");

  const handleSave = () => {
    // Save the updated gender and dob
    console.log("Updated Gender:", gender);
    console.log("Updated DOB:", dob);
    // Add your save logic here
  };

  const getUserDetails = async () => {
    const res = await getUser(context.user);
    setUser(res);
  };

  useEffect(() => {
    getUserDetails();
  }, [context.user]);

  return (
    <>
      {context.windowWidth > 992 && (
        <div className="breadcrumbWrapper mb-4">
          <div className="container-fluid">
            <ul className="breadcrumb breadcrumb2 mb-0">
              <li>
                <Link to={"/"}>Home</Link>
              </li>
              <li>User</li>
              <li>My Account</li>
            </ul>
          </div>
        </div>
      )}

      <section className="listingPage">
        <div className="container-fluid">
          <div className="listingData">
            <div className="row">
              {context.windowWidth > 992 && (
                <div className="col-md-3 sidebarWrapper">
                  <Usersidebar />
                </div>
              )}

              <div
                className={
                  context.windowWidth > 992 ? "col-md-9 pt-0" : "col-md-12 pt-0"
                }
              >
                <Box sx={{ padding: 2 }}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell sx={{ fontSize: "20px" }} variant="head">
                            Full Name
                          </TableCell>
                          <TableCell sx={{ fontSize: "20px" }}>
                            {user.merci_full_name}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontSize: "20px" }} variant="head">
                            Email
                          </TableCell>
                          <TableCell sx={{ fontSize: "20px" }}>
                            {user.merci_email}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontSize: "20px" }} variant="head">
                            Phone
                          </TableCell>
                          <TableCell sx={{ fontSize: "20px" }}>
                            {user.merci_phone}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontSize: "20px" }} variant="head">
                            Gender
                          </TableCell>
                          <TableCell sx={{ fontSize: "20px" }}>
                            {user.merci_gender ? (
                              user.merci_gender
                            ) : (
                              <TextField
                                label="Gender"
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                                variant="outlined"
                                fullWidth
                              />
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head" sx={{ fontSize: "20px" }}>
                            Date of Birth
                          </TableCell>
                          <TableCell sx={{ fontSize: "20px" }}>
                            {user.merci_dob ? (
                              user.merci_dob
                            ) : (
                              <TextField
                                label="Date of Birth"
                                value={dob}
                                onChange={(e) => setDob(e.target.value)}
                                variant="outlined"
                                fullWidth
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {(user.merci_dob === "" || user.merci_gender === "") && (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ marginTop: 2 }}
                    >
                      Save
                    </Button>
                  )}
                </Box>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Account;
