import Cookies from 'js-cookie'; 
import { updateWishlist } from '../features/wishlistSlice';
import { getDiscountPercentage } from './Percentages';

export const handleWishlistItems = (product, percentage, dispatch) => {

    const updatedProduct = {
        ...product,
        merci_count: 1,
        merci_mrp: getDiscountPercentage(
            percentage,
           product.merci_mrp,
            parseInt(product.merci_merci_discount)
        ).finalValue,
    };

   

    // Check if the cookie exists
    let wishlist = Cookies.get('emartWishlist');
    if (!wishlist) {
        // If cookie doesn't exist, create an array with the updated product
        wishlist = [updatedProduct];
    } else {
        // If cookie exists, parse the existing array and add the updated product
        wishlist = JSON.parse(wishlist);
        wishlist.push(updatedProduct);
    }

    // Update the cookie
    Cookies.set('emartWishlist', JSON.stringify(wishlist), { expires: 7 }); // Expires in 7 days

    // Dispatch the action
    dispatch(updateWishlist(updatedProduct));
    alert("Item Added to Wishlist");
};

export const deleteWishlistItem = (productId, dispatch) => {
    // Check if the cookie exists
    let wishlist = Cookies.get('emartWishlist');
    if (!wishlist) {
        alert("No items in the wishlist to delete.");
        return;
    }

    // Parse the existing array
    wishlist = JSON.parse(wishlist);

    // Filter out the product with the specified id
    const updatedWishlist = wishlist.filter(item => item.id !== productId);

    // Update the cookie
    Cookies.set('emartWishlist', JSON.stringify(updatedWishlist), { expires: 7 });

    // Optionally, you can dispatch an action to update the state
    dispatch(updateWishlist(updatedWishlist));
    alert("Item Removed from Wishlist");
};