import { COUPONURL, GlobalUserURL } from "../constants/constants";
import axios from "axios";

export const checkPhoneNumber = async (phone) => {
  const idVal = { phone: phone };
  const validCheck = await axios
    .post(`${GlobalUserURL}/phone/`, idVal)
    .then((res) => res.data);
  return validCheck;
};

export const createUser = async (userDetails) => {
  const validCheck = await axios
    .post(`${GlobalUserURL}/`, userDetails)
    .then((res) => res.data);
  return validCheck;
};

export const getUser = async (id) => {
  const userDetails = await axios
    .get(`${GlobalUserURL}/${id}`)
    .then((res) => res.data);
  return userDetails;
};

export const updateUser = async (id, updateUser) => {
  const userDetails = await axios
    .put(`${GlobalUserURL}/${id}`, {newData : updateUser})
    .then((res) => res.data);
  return userDetails;
};

export const loginUser = async (phone, password) => {
  const userDetails = { phone: phone, password: password };
  const loginres = await axios
    .post(`${GlobalUserURL}/login`, userDetails)
    .then((res) => res.data);
  return loginres;
};

export const getCouponsByUserId = async (userId) => {
    const response = await axios.get(`${COUPONURL}/user/${userId}`);
    return response.data;
};

export const changePassword = async (phone) => {
  const idVal = { phone: phone };
  const validCheck = await axios
    .post(`${GlobalUserURL}/changepass/`, idVal)
    .then((res) => res.data);
  return validCheck;
};

export const changePasswordbyEmail = async (id, password) => {
  const idVal = { id, password };
  const validCheck = await axios
    .post(`${GlobalUserURL}/changepassbyemail/`, idVal)
    .then((res) => res.data);
  return validCheck;
};

export const changeUserPassword = async (id, currentPassword, password) => {
  const idVal = { id, currentPassword, password };
  const validCheck = await axios
    .post(`${GlobalUserURL}/updatepassword/`, idVal)
    .then((res) => res.data);
  return validCheck;
};